import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ReportListPageParams, Reports, Report, CreateReportDto } from "../types/report.types";

@Injectable({ providedIn: 'root' })
export class ReportService {
  private baseUrl = `${environment.apiUrl}/reports`;

  constructor(private http: HttpClient) { }

  public createReport(createReportDto: CreateReportDto): Observable<Report> {
    return this.http.post<Report>(
      this.baseUrl, createReportDto, { withCredentials: true }
    );
  }

  public downloadReport(reportId: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.baseUrl}/download/${reportId}`, { 
      responseType: 'blob' as 'json',
      withCredentials: true 
    });
  }

  public getReports({ page, pageSize, orderByColumn, orderBy, search }: ReportListPageParams): Observable<Reports> {
    return this.http.get<Reports>(`${this.baseUrl}`, { 
      params: new HttpParams()
        .set('search', search || '')
        .set('page', page?.toString() || 0)
        .set('pageSize', pageSize?.toString() || 10)
        .set('orderByColumn', orderByColumn || '_id')
        .set('orderBy', orderBy || 'DESC'),
      withCredentials: true 
    });
  }
}
