<div class="container">
  <app-card class="d-flex reports-list flex-column h-100 p-4">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="mb-3">Reports</h2>
      <div class="d-flex align-items-center justify-content-center gap-3">
        <button
          mat-raised-button
          color="primary"
          (click)="handleButtonClick()"
        >
          Create Report
        </button>
      </div>
      <ng-content></ng-content>
    </div>
    <mat-form-field> 
      <input matInput placeholder="Search Reports" #input>
    </mat-form-field>
    <div class="list-container">
      <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">No data received</h3>
      <app-table
        *ngIf="tableRowData.length"
        [columnsHeadersMap]="tableColumnsAndHeaders"
        [rowData]="tableRowData"
        [disabledSortColumns]="disabledSortColumns"
        [paginatorConfig]="paginatorConfig"
        (onPageClick)="handlePageClick($event)"
        (onSortClick)="handleSortClick($event)"
        (onDownloadButtonClick)="handleDownloadReportClick($event)"
      ></app-table>
    </div>
  </app-card>
</div>

<ng-container>
  <ng-template #createReportModalContentTemplate>
    <span class="fw-bold fs-3">Create Report</span>
  </ng-template>
  <ng-template #createReportModalActionsTemplate>
    <form [formGroup]="reportForm">
      <div class="col-sm-6 w-100">
        <mat-form-field
          class="w-100"
          hideRequiredMarker
          appearance="outline"
        >
          <mat-label>Affiliates</mat-label>
          <mat-select formControlName="affiliateId">
            <mat-option *ngFor="let option of affiliateInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('affiliateId', 'Affiliate') }}
          </mat-error>
        </mat-form-field>    
      </div>
      <div class="col-sm-6 w-100">
        <mat-form-field
        class="w-100"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let option of reportTypesInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('type', 'Type') }}
        </mat-error>
      </mat-form-field>
      </div>
      <div class="col-sm-6 w-100">
        <mat-form-field
          class="w-100"
          hideRequiredMarker
          appearance="outline"
        >
          <mat-label>Suppliers</mat-label>
          <mat-select formControlName="supplierId">
            <mat-option *ngFor="let option of supplierInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('supplierId', 'Supplier') }}
          </mat-error>
        </mat-form-field>  
      </div>      
      <div class="col-sm-6 w-100">
        <app-date-input
          formControlName="from"
          label="From Date"
          [maxDate]="maxReportDate"
        ></app-date-input>
      </div>
      <div class="col-sm-6 w-100">
        <app-date-input
          formControlName="to"
          label="To Date"
          [maxDate]="maxReportDate"
        ></app-date-input>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-3 py-3">
        <button
        mat-button
        color="primary"
        (click)="handleModalClose()"
      >
        Cancel
      </button>
        <button
        type="button" 
        mat-raised-button
        color="primary"
        [disabled]="reportForm.invalid"
        (click)="handleModalCreateReportActionConfirmationButtonClick()"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-template>
</ng-container>
