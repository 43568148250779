import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

import { CoreService } from "../../../../core/services/core.service";
import { AffiliateService } from "../../../../core/services/affiliate.service";
import { AffiliateDigitalLeadSetting } from "../../../../core/types/affiliate.types";

@Component({
  selector: 'app-activate-deactivate-affiliate-digital-lead-setting-modal',
  templateUrl: './activate-deactivate-affiliate-digital-lead-setting-modal.component.html'
})
export class ActivateDeactivateAffiliateDigitalLeadSettingModalComponent {
  @ViewChild('activateDeactivateAffiliateDigitalLeadSettingModalContentTemplate')
  public activateDeactivateAffiliateDigitalLeadSettingModalContentTemplate: TemplateRef<any>;
  @ViewChild('activateDeactivateAffiliateDigitalLeadSettingModalActionsTemplate')
  public activateDeactivateAffiliateDigitalLeadSettingModalActionsTemplate: TemplateRef<any>;
  @Output() public cancel: EventEmitter<AffiliateDigitalLeadSetting> = new EventEmitter();
  @Output() public confirm: EventEmitter<void> = new EventEmitter();

  constructor(
    private coreService: CoreService,
    private affiliatesService: AffiliateService,
    private toastrService: ToastrService
  ) {}

  public handleCancelButtonClick(setting: AffiliateDigitalLeadSetting): void {
    this.cancel.emit(setting);
  }

  public handleModalToggleActionConfirmationButtonClick(setting: AffiliateDigitalLeadSetting): void {
    this.coreService.setIsLoading(true);
    this.affiliatesService
      .putAffiliateDigitalLeadSettings(setting.affiliate._id, setting)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        complete: () => {
          this.confirm.emit();
          this.toastrService.success(
            `Setting has been successfully ${setting.active ? 'activated' : 'deactivated'}`, 
            undefined, 
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
  }
}
