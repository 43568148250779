<ng-template #deleteDirectmailsImportModalContentTemplate>
  <span>Are you sure you want to delete this file?</span>
</ng-template>
<ng-template #deleteDirectmailsImportModalActionsTemplate let-modalData>
  <ul><li class="fw-bold">{{ modalData.fileName }}</li></ul>
  <div class="
    d-flex
    align-items-center
    gap-3
    justify-content-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick()"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleModalDeleteConfirmationButtonClick(modalData)"
    >
      Delete
    </button>
  </div>
</ng-template>