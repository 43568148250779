import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import {
  CreateLandingPagePayload,
  EditLandingPagePayload,
  LandingPage,
  LandingPagesListPageParams,
  LandingPagesResponse
} from "../types/landing-page.types";

@Injectable({ providedIn: 'root' })
export class LandingPageService {
  private apiUrl = `${environment.apiUrl}/landing-pages`;

  constructor(private http: HttpClient) {}

  public createLandingPage(createLandingPagePayload: CreateLandingPagePayload): Observable<LandingPage> {
    return this.http.post<LandingPage>(`${this.apiUrl}`, createLandingPagePayload);
  }

  public getAllLandingPagesPaginate({ page, pageSize, orderByColumn, orderBy, search, active }: LandingPagesListPageParams): Observable<LandingPagesResponse> {
    let params = new HttpParams()
    .set('page', page || 0)
    .set('pageSize', pageSize || 10)
    .set('orderBy', orderBy || 'DESC')
    .set('orderByColumn', orderByColumn || '_id')

    if (search) {
      params = params.set('search', search);
    }

    if (typeof active === 'boolean') {
      params = params.set('active', active);
    }

    return this.http.get<LandingPagesResponse>(`${this.apiUrl}/paginate`, { params });
  }

  public getAllLandingPages(): Observable<LandingPage[]> {
    return this.http.get<LandingPage[]>(`${this.apiUrl}/?active=true`);
  }

  public getLandingPage(landingPageId: string): Observable<LandingPage> {
    return this.http.get<LandingPage>(`${this.apiUrl}/${landingPageId}`);
  }

  public editLandingPage(landingPageId: string, data: EditLandingPagePayload): Observable<LandingPage> {
    return this.http.patch<LandingPage>(`${this.apiUrl}/${landingPageId}`, data);
  }
}
