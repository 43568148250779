<ng-container *ngIf="breadcrumbs.length > 1">
  <div class="d-flex align-items-center justify-content-center">
    <a class="fw-bold ps-4 d-block d-sm-none" [routerLink]="breadcrumbs[0].url">
      {{ breadcrumbs[0].label.length > 11 ? breadcrumbs[0].label.slice(0, 11) + '...' : breadcrumbs[0].label }}
    </a>

    <a class="fw-bold ps-4 d-none d-sm-block" [routerLink]="breadcrumbs[0].url">
      {{ breadcrumbs[0].label }}
    </a>

    <ng-container *ngFor="let breadcrumb of breadcrumbs | slice:1">
      <span class="d-block px-2" [class.active-route]="router.url === breadcrumb.url">
        >>
      </span>
      <a 
        class=""
        [class.active-route]="router.url === breadcrumb.url"
        [routerLink]="breadcrumb.url"
      >{{ breadcrumb.label }}</a>
    </ng-container>
  </div>
</ng-container>