import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable, finalize, lastValueFrom, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { CoreService } from "../../../../core/services/core.service";
import { CreateUserPayload, AdminUpdateUserPayload, User } from "../../../../core/types/user.types";
import { UsersService } from "../../../../core/services/users.service";
import { AffiliateService } from "../../../../core/services/affiliate.service";
import { Roles } from "../../../../core/types/role.type";
import { AuthService } from "../../../../core/services/auth.service";
import { Affiliate } from "../../../../core/types/affiliate.types";

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-users-creation-edition',
  templateUrl: '/users-creation-edition.component.html'
})
export class UsersCreationEditionComponent implements OnInit {
  public userId: string;
  public userForm;
  public roleInputOptions: SelectOption[] = [
    { label: Roles.Admin, value: Roles.Admin },
    { label: Roles.Affiliate, value: Roles.Affiliate },
    { label: Roles.CustomerService, value: Roles.CustomerService },
    { label: Roles.SuperAdmin, value: Roles.SuperAdmin }
  ];
  public affiliateInputOptions: SelectOption[] = [];
  private loggedUserRole?: Roles;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private affiliatesService: AffiliateService,
    private coreService: CoreService,
    private usersService: UsersService,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.userId = this.route.snapshot.params['id'];
    this.userForm = this.fb.nonNullable.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      affiliate: [{ value: '', disabled: true }, Validators.required],
      active: true
    });
  }

  public ngOnInit(): void {
    const loggedUser = this.authService.getLoggedUser();

    this.loggedUserRole = loggedUser.attributes['custom:role']! as Roles;
    this.userForm.controls.role.valueChanges.subscribe(this.handleRoleInputChange.bind(this));

    setTimeout(() => {
      this.coreService.setIsLoading(true);
      this.setupAffiliates()
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe({
          complete: () => {
            if (this.userId) {
              this.getUser();
            }

            this.setFormInitialState();
          },
          error: (error) => {
            this.toastrService.error(
              error,
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
          }
        });
    });
  }

  public async handleUserFormSubmit(): Promise<void> {
    if (this.userForm.valid) {
      try {
        this.coreService.setIsLoading(true);

        const userPayload = this.userForm.getRawValue();
  
        if (this.userId) {
          await lastValueFrom(
            this.usersService.adminUpdateUser(this.userId, userPayload as AdminUpdateUserPayload)
          );
        } else {
          await lastValueFrom(
            this.usersService.createUser(userPayload as CreateUserPayload)
          );
        }
  
        const routePath = this.userId ? '../../../users' : '../../users';
  
        this.router.navigate(
          [routePath],
          { relativeTo: this.route }
        );
        this.toastrService.success(
          `User successfully ${this.userId ? 'updated' : 'created'}`,
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
      } catch (error: any) {
        this.toastrService.error(
          error,
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public getErrorMessage(controlName: keyof typeof this.userForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.userForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    }

    return errorMessage;
  }

  public handleBackButtonClick(): void {
    this.router.navigate(['/admin/users']);
  }

  private setupAffiliates(): Observable<Affiliate[]> {
    return this.affiliatesService
      .getAllAffiliates()
      .pipe(
        tap((affiliates) => {
          this.affiliateInputOptions = affiliates.map((affiliate) => ({
            label: affiliate.name,
            value: affiliate._id
          }));
        })
      );
  }

  private getUser(): void {
    this.usersService
      .findUser(this.userId)
      .subscribe((user) => {
        this.updateUserFormInitialValues(user);
      });
  }

  private updateUserFormInitialValues(user: User): void {
    this.userForm.controls.email.disable();
    this.userForm.patchValue({
      affiliate: user.affiliate,
      email: user.email,
      name: user.name,
      role: user.role
    });

    if (user.affiliate) {
      this.userForm.controls.affiliate.enable();
    }
  }

  private setFormInitialState(): void {
    if (this.loggedUserRole === Roles.Admin) {
      const affiliateRole = this.roleInputOptions.find((roleInputOption) => roleInputOption.label === Roles.Affiliate);

      this.userForm.controls.role.setValue(affiliateRole!.value);
      this.userForm.controls.role.disable();
      this.userForm.controls.affiliate.enable();
    }
  }
  
  private handleRoleInputChange(role: string): void {
    if (role === Roles.Affiliate) {
      this.userForm.controls.affiliate.enable();
    } else {
      this.userForm.controls.affiliate.setValue('');
      this.userForm.controls.affiliate.disable();
    }
  }
}
