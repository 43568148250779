<app-card class="p-4">
  <form [formGroup]="digitalLeadsSupplierForm">
    <mat-form-field class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error>
        {{ getErrorMessage('name', 'Name') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="w-100"
      *ngIf="digitalLeadsSupplierId"
      hideRequiredMarker
      appearance="outline"
    >
      <mat-label>API Key</mat-label>
      <input
        matInput
        formControlName="apiKey"
        type="text"
        [ngClass]="getSecurityInputClass()"
      />

      <mat-icon matSuffix (click)="toggleVisibility()">
        {{ isInputVisible ? 'visibility' : 'visibility_off' }}
      </mat-icon>
      <mat-error>
        {{ getErrorMessage('apiKey', 'API Key') }}
      </mat-error>
    </mat-form-field>    
    <div
      *appHasRole="[roles.SuperAdmin]"
      class="
        d-flex
        align-items-center
        justify-content-end
        gap-4
        py-3
      "
    >
      <div class="flex-grow-1">
        <button
          *ngIf="digitalLeadsSupplierId"
          mat-raised-button
          color="primary"
          (click)="handleChangeAPIKeyButtonClick()"
        >
          Generate new API key
        </button>
      </div>    
      <div class="d-flex gap-4">
        <button
        mat-button
        color="primary"
        (click)="handleBackButtonClick()"
      >
        Back
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="digitalLeadsSupplierForm.invalid"
        (click)="handleContinueButtonClick()"
      >
        Save
      </button>
      </div>
    </div>
  </form>
</app-card>
<router-outlet></router-outlet>

<ng-container>
  <ng-template #changeDigitalLeadsSupplierAPIKeyModalContentTemplate>
    <span>Are you sure you want to change the Digital Leads Supplier API key?</span>
  </ng-template>
  
  <ng-template #changeDigitalLeadsSupplierAPIKeyModalActionsTemplate>
    <div class="d-flex align-items-center gap-5 justify-content-end">
      <button
        mat-button
        color="primary"
        (click)="modalRef!.close()"
      >
        Cancel
      </button>
      <button
        mat-button
        color="primary"
        (click)="handleModalChangeAPIKeyConfirmationButtonClick()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</ng-container>