import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements AfterViewInit, ControlValueAccessor {
  @Input() label: string;
  @Input() formControlName: string;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @ViewChild('matFormFieldElement', { read: ElementRef }) private matFormFieldElementRef: ElementRef;
  public value: number | string;
  public onChange: (value: number | string) => void
  public onTouched: () => void;
  private matFormFieldWrapperElement: HTMLElement;
  private suffixElement: HTMLElement;

  constructor(public control: NgControl, private renderer: Renderer2) {
    this.control.valueAccessor = this;
  }

  public ngAfterViewInit(): void {
    this.matFormFieldWrapperElement =
      this.matFormFieldElementRef.nativeElement.querySelector('.mat-mdc-text-field-wrapper');
    this.suffixElement = this.matFormFieldElementRef.nativeElement.querySelector('.suffix');
  }

  public registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  public handleInput(event: any): void {
    const value = event.target.value;
    const formattedValue = value ? Number(value) : '';

    this.control.control?.setValue(formattedValue);
    this.writeValue(formattedValue);
    this.setSuffixPosition();
    this.toggleMatFormFieldWrapperInvalidClass();
  }

  public writeValue(value: number | string): void {
    this.value = value;
  }

  public handleChange(): void {
    this.onChange(this.value);
  }

  public handleBlur(): void {
    this.toggleMatFormFieldWrapperInvalidClass();
    this.onTouched();
  }

  public getErrorMessage(): string {
    let errorMessage = '';

    if (this.control.hasError('required')) {
      errorMessage = `${this.label} is required`;
    } else if (this.control.hasError('min')) {
      errorMessage = `${this.label} is less than ${this.control.errors!['min'].min}`;
    } else if (this.control.hasError('max')) {
      errorMessage = `${this.label} is greater than ${this.control.errors!['max'].max}`;
    }

    return errorMessage;
  }

  private setSuffixPosition(): void {
    const inputValueLength = String(this.value).length;
    const pxToMove = 10;

    this.suffixElement.style.left = `${inputValueLength * pxToMove}px`;
  }

  private toggleMatFormFieldWrapperInvalidClass(): void {
    if (this.control.valid) {
      this.renderer.removeClass(this.matFormFieldWrapperElement, 'mdc-text-field--invalid');
    } else {
      this.renderer.addClass(this.matFormFieldWrapperElement, 'mdc-text-field--invalid');
    }
  }
}
