<app-card class="p-4">
  <form [formGroup]="affiliateForm">
    <mat-form-field class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error>
        {{ getErrorMessage('name', 'Name') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="w-100"
      hideRequiredMarker
      appearance="outline"
    >
      <mat-label>Mobilend API Key</mat-label>
      <input
        matInput
        formControlName="mobilendApiKey"
        [ngClass]="getSecurityInputClass()"
      />

      <mat-icon matSuffix (click)="toggleVisibility()">
        {{ isInputVisible ? 'visibility' : 'visibility_off' }}
      </mat-icon>
      <mat-error>
        {{ getErrorMessage('mobilendApiKey', 'Mobilend API Key') }}
      </mat-error>
    </mat-form-field>
    <div class="
      d-flex
      align-items-center
      justify-content-end
      gap-4
      py-3
    ">
      <div class="d-flex gap-4">
        <button
        mat-button
        color="primary"
        (click)="handleBackButtonClick()"
      >
        Back
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="affiliateForm.invalid"
        (click)="handleContinueButtonClick()"
      >
        Save
      </button>
      </div>
    </div>
  </form>
</app-card>
<router-outlet></router-outlet>