import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  Affiliate,
  EditAffiliatePayload,
  AffiliateListPageParams,
  CreateAffiliatePayload,
  AffiliatesResponse,
  AffiliateDigitalLeadSettingsResponse,
  AffiliateDigitalLeadSettingsParams,
  AffiliateDigitalLeadSetting,
  PutAffiliateDigitalLeadSettingDto
} from "../types/affiliate.types";

@Injectable({ providedIn: 'root' })
export class AffiliateService {
  private apiUrl = `${environment.apiUrl}/affiliates`;

  constructor(private http: HttpClient) {}

  public createAffiliate(createAffiliateDto: CreateAffiliatePayload): Observable<Affiliate> {
    return this.http.post<Affiliate>(`${this.apiUrl}`, createAffiliateDto, { withCredentials: true });
  }

  public getAllAffiliatesPaginate({ page, pageSize, orderByColumn, orderBy, search, active }: AffiliateListPageParams): Observable<AffiliatesResponse> {
    let params = new HttpParams()
      .set('page', page || 0)
      .set('pageSize', pageSize || 10)
      .set('orderBy', orderBy || 'DESC')
      .set('orderByColumn', orderByColumn || '_id');

    if (search) {
      params = params.set('search', search);
    }
    
    if (typeof active === 'boolean') {
      params = params.set('active', active);
    }

    return this.http.get<AffiliatesResponse>(`${this.apiUrl}/find/paginate`, { params });
  }

  public getAllAffiliates(): Observable<Affiliate[]> {
    return this.http.get<Affiliate[]>(`${this.apiUrl}/?active=true`, { withCredentials: true });
  }

  public getAffiliate(affiliateId: string): Observable<Affiliate> {
    return this.http.get<Affiliate>(`${this.apiUrl}/${affiliateId}`, { withCredentials: true });
  }

  public editAffiliate(affiliateId: string, data: EditAffiliatePayload): Observable<Affiliate> {
    return this.http.patch<Affiliate>(`${this.apiUrl}/${affiliateId}`, data, { withCredentials: true });
  }

  public findAffiliate(affiliateId: string): Observable<Affiliate> {
    return this.http.get<Affiliate>(`${this.apiUrl}/${affiliateId}`, { withCredentials: true });
  }

  public putAffiliateDigitalLeadSettings(
    affiliateId: string,
    putAffiliateDigitalLeadSettingDto: PutAffiliateDigitalLeadSettingDto
  ): Observable<AffiliateDigitalLeadSetting> {
    return this.http.put<AffiliateDigitalLeadSetting>(
      `${this.apiUrl}/${affiliateId}/digital-lead-settings`, 
      putAffiliateDigitalLeadSettingDto,
      { withCredentials: true }
    );
  }

  public getAffiliateDigitalLeadSettings(
    params?: AffiliateDigitalLeadSettingsParams
  ): Observable<AffiliateDigitalLeadSettingsResponse> {
    let httpParams = new HttpParams();

    if (params?.page !== undefined && params?.pageSize !== undefined) {
      httpParams = httpParams
        .set('page', params.page)
        .set('pageSize', params.pageSize);
    }

    if (params?.orderBy && params?.orderByColumn) {
      httpParams = httpParams
        .set('orderBy', params.orderBy)
        .set('orderByColumn', params.orderByColumn);
    }

    return this.http.get<AffiliateDigitalLeadSettingsResponse>(
      `${this.apiUrl}/digital-lead-settings`,
      { params: httpParams, withCredentials: true }
    );
  }
}
