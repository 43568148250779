import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, tap } from "rxjs";
import { format } from "date-fns";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { RowData, SortConfigOptions, ToggleEvent } from "../../../shared/components/table/table.component";
import { CoreService } from "../../../core/services/core.service";
import { PaginatorConfigOptions } from "../../../shared/components/table/table.component";
import { AuthService } from "../../../core/services/auth.service";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { Affiliate, AffiliateListPageParams } from "../../../core/types/affiliate.types";
import { AffiliateService } from "../../../core/services/affiliate.service";

interface TableRowData {
  _id: string;
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {
  @ViewChild('activeAndDesactiveModalContentTemplate')
  private activeAndDesactiveModalContentTemplate: TemplateRef<any>;
  @ViewChild('activeAndDesactiveModalActionsTemplate')
  private activeAndDesactiveModalActionsTemplate: TemplateRef<any>;
  public isLoading: Observable<boolean>;
  public modalRef?: MatDialogRef<ModalComponent>;
  public paginatorConfig: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: 'DESC',
    orderByColumn: '_id'
  };
  public affiliates: Affiliate[];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['name', 'Name'],
    ['createdAt', 'Date Created'],
    ['updatedAt', 'Date Updated'],
  ]);
  public tableRowData: TableRowData[] = [];

  @ViewChild('input') input: ElementRef;

  constructor(
    public router: Router,
    private coreService: CoreService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private affiliateService: AffiliateService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getAllAffiliates();
    });
  }

  public ngAfterViewInit(): void {
    if (this?.input?.nativeElement) {
      fromEvent(this.input.nativeElement,'keyup')
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(async () => {
              this.getAffiliatesData({
                page: 0, 
                pageSize: this.paginatorConfig.pageSize!,
                orderByColumn: this.sortConfig.orderByColumn,
                orderBy: this.sortConfig.orderBy,
                search: this.input.nativeElement.value
              });
            })
        )
        .subscribe();
    }
  } 

  public handleTableRowClick(row: RowData): void {
    this.router.navigate(
      ['../affiliates/creation', row['_id']],
      { relativeTo: this.route }
    );
  }

  public handlePageClick(event: PageEvent): void {
    this.getAffiliatesData({
      page: event.pageIndex, 
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getAffiliatesData({
      page: 0,
      pageSize: this.paginatorConfig.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction
    });
  }

  public handleToggleClick({ event, row }: ToggleEvent): void {
    const affiliate = row as TableRowData;

    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        title: 'Active/Deactivate Affiliate',
        contentTemplate: this.activeAndDesactiveModalContentTemplate,
        actionsTemplate: this.activeAndDesactiveModalActionsTemplate,
        context: { affiliate: affiliate, action: event.checked }
      },
      restoreFocus: false
    });
  }

  public handleModalToggleActionConfirmationButtonClick({ affiliate, action }: { affiliate: TableRowData, action: boolean }): void {
    this.coreService.setIsLoading(true);
    this.affiliateService.editAffiliate(
      affiliate._id, { active: action }
    ).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
      ).subscribe(() => {
        this.modalRef!.close();
        this.modalRef = undefined;
        this.toastrService.success(
          `Affiliate has been successfully ${action ? 'activated' : 'deactivated'}`, 
          undefined, 
          { positionClass: 'toast-custom-bottom-center' }
        );
      })
  }

  public handleModalToggleActionCancelButtonClick({ affiliate, action }: { affiliate: TableRowData, action: boolean }): void {
    const affiliateIndex = this.tableRowData.findIndex(customer => customer._id === affiliate._id);
    
    this.tableRowData[affiliateIndex].active = !action;
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private getAllAffiliates(): void {
    this.coreService.setIsLoading(true);
    this.affiliateService.getAllAffiliatesPaginate({
      page: this.paginatorConfig.pageIndex!, 
      pageSize: this.paginatorConfig.pageSize!
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllAffiliatesResponse) => {
      const { affiliates, ...pageData } = getAllAffiliatesResponse;
      this.affiliates = affiliates;
      this.paginatorConfig.length = pageData.totalItems;
      this.prepareTableData();
    })
  }

  public getAffiliatesData(params: AffiliateListPageParams): void {
    this.coreService.setIsLoading(true);
    this.affiliateService.getAllAffiliatesPaginate({
      search: params.search,
      page: params.page || 0,
      pageSize: params.pageSize || 10,
      orderByColumn: params.orderByColumn || '_id',
      orderBy: params.orderBy || 'DESC',
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllAffiliatesResponse) => {
      const { affiliates, ...pageData } = getAllAffiliatesResponse;
      this.affiliates = affiliates;
      this.paginatorConfig.length = pageData.totalItems;
      this.paginatorConfig.pageIndex = pageData.page;
      this.paginatorConfig.pageSize = pageData.itemsPerPage;
      this.sortConfig.orderBy = pageData.orderBy;
      this.sortConfig.orderByColumn = pageData.orderByColumn;
      this.prepareTableData();
    })
  }

  public handleAddNewAffiliateButtonClick(): void {
    this.router.navigate(
      ["../affiliates/creation"],
      { relativeTo: this.route }
    );
  }

  private prepareTableData(): void {
    this.tableRowData = this.affiliates.map((affiliate) => {
      const createdAt = this.formateDate(affiliate.createdAt);
      const updatedAt = this.formateDate(affiliate.updatedAt);

      return {
        _id: affiliate._id,
        name: affiliate.name,
        active: affiliate.active,
        createdAt,
        updatedAt
      };
    });
  }

  private formateDate(date: Date): string {
    const newDate = new Date(date);
    return format(new Date(newDate), 'MM/dd/yyyy')
  }
}
