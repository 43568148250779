<app-card class="users-list">
  <div class="d-flex align-items-center justify-content-between">
    <h2>Users</h2>
    <div class="d-flex align-items-center justify-content-center gap-3">
      <button
        mat-raised-button
        color="primary"
        (click)="handleAddButtonClick()"
      >
        Add
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="handleImportButtonClick()"
      >
        Import
      </button>
    </div>
  </div>
  <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">
    No data received
  </h3>
  <app-table
    *ngIf="tableRowData.length"
    [clickableRow]="true"
    [columnsHeadersMap]="tableColumnsAndHeaders"
    [rowData]="tableRowData"
    (onRowClick)="handleTableRowClick($event)"
    (onToggleClick)="handleToggleClick($event)"
    (onDeleteButtonClick)="handleDeleteButtonClick($event)"
  ></app-table>
</app-card>

<app-import-users-modal
  [affiliates]="affiliates"
  (cancel)="closeModal()"
  (confirm)="handleImportUsersModalConfirm()"
></app-import-users-modal>

<app-activate-deactivate-user-modal
  (cancel)="handleActivateDeactivateUserModalCancelButtonClick($event)"
  (confirm)="closeModal()"
></app-activate-deactivate-user-modal>

<app-delete-user-modal
  (cancel)="closeModal()"
  (confirm)="handleDeleteUserModalConfirmButtonClick($event)"
></app-delete-user-modal>