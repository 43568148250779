import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, lastValueFrom } from 'rxjs';

import { CoreService } from '../../../../../core/services/core.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../../../shared/components/modal/modal.component';
import { ToastrService } from 'ngx-toastr';
import { DigitalLeadsService } from '../../../../../core/services/digital-leads.service';
import { LandingPageService } from '../../../../../core/services/landing-page.service';
import { DigitalLead } from '../../../../../core/types/digital-leads.types';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-send-leads-to-campaign',
  templateUrl: './send-leads-to-campaign.component.html',
  styleUrls: ['./send-leads-to-campaign.component.scss']
})
export class SendLeadsToCampaignComponent implements OnInit {
  @Input() public digitalLeadsSelected: DigitalLead[];
  @Input() public modalRef?: MatDialogRef<ModalComponent>;
  @Output() public onAction: EventEmitter<void> = new EventEmitter<void>();
  public sendToCampaignForm: FormGroup;
  public campaignInputOptions: SelectOption[] = [];

  constructor(
    private coreService: CoreService, 
    private digitalLeadsService: DigitalLeadsService,
    private landingPageService: LandingPageService,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.sendToCampaignForm = this.fb.nonNullable.group({
      campaign: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.sendToCampaignForm.reset();    
    setTimeout(() => {
      this.getAllCampaigns();
    });
  }

  public async handleConfirmationButtonClick(): Promise<void> {
    this.sendToCampaignForm.markAllAsTouched();
    
    if (this.sendToCampaignForm.valid) {
      this.coreService.setIsLoading(true);

      const { campaign } = this.sendToCampaignForm.value;

      try {
        await lastValueFrom(
          this.digitalLeadsService.sendDigitalLeadsToCampaign(
            campaign, 
            { digitalLeads: this.digitalLeadsSelected }
          )
         );
        
         this.onAction.emit();
         const successMessage = `Digital Leads successfully sent to campaign: ${campaign}`;
         this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
      } catch(error) {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public handleModalClose(): void {
    this.modalRef!.close();
    this.sendToCampaignForm.reset();
  }

  public getErrorMessage(controlName: keyof typeof this.sendToCampaignForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.sendToCampaignForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  private getAllCampaigns(): void {
    this.coreService.setIsLoading(true);
    this.landingPageService.getAllLandingPages().pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllCampaignsResponse) => {
      const landingPages = getAllCampaignsResponse;

      landingPages.forEach(landingPage => {
        this.campaignInputOptions.push({ label: landingPage.campaign, value: landingPage.campaign })
      });
    })
  }
}
