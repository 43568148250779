export interface Affiliate {
  _id: string;
  name: string;
  mobilendApiKey: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface AffiliatesResponse {
  affiliates: Affiliate[]
  page: number
  totalItems: number;
  itemsPerPage: number;
  orderBy: string
  orderByColumn: string;
}

export interface AffiliateListPageParams {
  page: number;
  pageSize:number; 
  orderByColumn?: string;
  orderBy?: string;
  search?: string;
  active?: boolean;
}

export interface EditAffiliatePayload {
  name?: string;
  mobilendApiKey?: string;
  active?: boolean;
}

export interface CreateAffiliatePayload {
  name: string;
}

export interface AffiliateDigitalLeadSetting {
  _id: string;
  active: boolean;
  affiliate: Affiliate;
  amount: number;
  campaign: string;
  createdAt: string;
  daysOfWeek: number[];
  fromHour: number;
  scheduling: string;
  states: string[];
  timezone: string;
  toHour: number;
  updatedAt: string;
}

export interface AffiliateDigitalLeadSettingsParams {
  page?: number;
  pageSize?: number; 
  orderByColumn?: string;
  orderBy?: string;
}

export interface AffiliateDigitalLeadSettingsResponse {
  affiliateDigitalLeadSettings: AffiliateDigitalLeadSetting[];
  itemsPerPage: number;
  orderBy: string;
  orderByColumn: string;
  page: number
  totalItems: number;
}

export interface PutAffiliateDigitalLeadSettingDto {
  active?: boolean;
  campaign: string;
  daysOfWeek: number[];
  fromHour: number;
  toHour: number;
  timezone: string;
  amount: number;
  states?: string[];
}

export const affiliateDigitalLeadSettingDayOptions = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
];