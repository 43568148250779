import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, lastValueFrom, tap } from "rxjs";
import { format } from "date-fns";

import { CoreService } from "../../../core/services/core.service";
import { PaginatorConfigOptions, RowData, SortConfigOptions } from "../../../shared/components/table/table.component";
import { CreateReportDto, Report, ReportListPageParams, ReportTypes } from "../../../core/types/report.types";
import { ReportService } from "../../../core/services/report.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AffiliateService } from "../../../core/services/affiliate.service";
import { ToastrService } from "ngx-toastr";
import { AuthenticatedUser } from "../../../core/types/auth.types";
import { AuthService } from "../../../core/services/auth.service";
import { DigitalLeadsSuppliersService } from "../../../core/services/digital-leads-suppliers.service";

interface TableRowData {
  _id: number;
  affiliate: string;
  user: string;
  period: string;
  createdAt: string;
}

interface SelectOption {
  label: string;
  value: number | string;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {
  @Input() public tableHeader: string;
  @ViewChild('createReportModalContentTemplate')
  private createReportModalContentTemplate: TemplateRef<any>;
  @ViewChild('createReportModalActionsTemplate')
  private createReportModalActionsTemplate: TemplateRef<any>;
  @ViewChild('input') private input: ElementRef;

  public isLoading: Observable<boolean>;
  public affiliateInputOptions: SelectOption[] = [];
  public supplierInputOptions: SelectOption[] = [];
  public reportTypesInputOptions: SelectOption[] = [
    { label: 'Digital Leads', value: ReportTypes.DigitalLeads }
  ];
  public disabledSortColumns: string[] = ['period'];
  public loggedUser: AuthenticatedUser;
  public modalRef?: MatDialogRef<ModalComponent>;
  public paginatorConfig?: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: 'DESC',
    orderByColumn: 'id'
  };
  public reports: Report[] = [];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['user', 'Created By'],
    ['type', 'Type'],
    ['affiliate', 'Affiliate'],
    ['period', 'Period'],
    ['createdAt', 'Created'],
  ]);
  public tableRowData: TableRowData[] = [];
  public reportForm: FormGroup;

  constructor(
    private coreService: CoreService,
    private reportService: ReportService,
    private authService: AuthService,
    private affiliateService: AffiliateService,
    private digitalLeadsSuppliersService: DigitalLeadsSuppliersService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public router: Router,
  ) {
    this.isLoading = this.coreService.isLoading$;
    this.reportForm = this.fb.nonNullable.group({
      affiliateId: [''],
      supplierId: [{ value: '', disabled: true }, Validators.required],
      type: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser()!;

    this.reportForm.controls['type'].valueChanges.subscribe((type) => {
      this.onTypeSelectionChange(type);
    });

    setTimeout(() => {
      this.getReports({ orderBy: this.sortConfig.orderBy });
      this.getAllAffiliates();
      this.getAllSuppliers();
    });
  }

  public get maxReportDate(): Date {
    return new Date();
  }

  public getErrorMessage(controlName: keyof typeof this.reportForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.reportForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  public ngAfterViewInit(): void {
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(() => {
              this.getReports({
                pageSize: this.paginatorConfig?.pageSize!,
                orderByColumn: this.sortConfig.orderByColumn,
                orderBy: this.sortConfig.orderBy,
                search: this.input.nativeElement.value
              });
            })
        )
        .subscribe();
    }
  }

  public handleDownloadReportClick(row: RowData): void {
    const reportRowData = row as TableRowData;
    this.coreService.setIsLoading(true);

    this.reportService.downloadReport(reportRowData._id)
    .pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    )
    .subscribe((blob) => {
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      
      let context = 'all_clients';

      if (reportRowData.affiliate !== 'N/A') {
        context = reportRowData.affiliate.toLowerCase().replace(/ /g, '_');        
      }
  
      downloadLink.href = url;
      downloadLink.download = `report_${context}_${Date.now()}.csv`;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    })
  }

  public handleButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        contentTemplate: this.createReportModalContentTemplate,
        actionsTemplate: this.createReportModalActionsTemplate,
      },
      restoreFocus: false
    });
  }

  public async handleModalCreateReportActionConfirmationButtonClick(): Promise<void> {
    this.reportForm.markAllAsTouched();
    
    if (this.reportForm.valid) {
      this.coreService.setIsLoading(true);

      const createFormPayload = this.reportForm.value as CreateReportDto;

      try {
        const report = await lastValueFrom(
          this.reportService.createReport(createFormPayload)
         );
        
         this.reports.unshift(report);
         this.prepareTableData();

         const successMessage = `Report successfully created`
         this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
         this.handleModalClose();
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public handlePageClick(event: PageEvent): void {
    this.getReports({
      page: event.pageIndex,
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getReports({
      page: 0,
      pageSize: this?.paginatorConfig?.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction
    });
  }

  public handleModalClose(): void {
    this.modalRef!.close();
    this.reportForm.reset();
  }

  public getReports(params?: ReportListPageParams): void {
    const search = params?.search?.replace(/,/g, '');

    this.coreService.setIsLoading(true);
    this.reportService
      .getReports({
        pageSize: params?.pageSize,
        page: params?.page,
        search: search,
        orderByColumn: params?.orderByColumn,
        orderBy: params?.orderBy,
      }).pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((getAllReportsResponse) => {
        const { reports, ...pageData } = getAllReportsResponse;
        this.reports = reports;
        if (!!this.paginatorConfig) {
          this.paginatorConfig.length = pageData.totalItems;
          this.paginatorConfig.pageIndex = pageData.page;
          this.paginatorConfig.pageSize = pageData.itemsPerPage;
          this.sortConfig.orderBy = pageData.orderBy;
          this.sortConfig.orderByColumn = pageData.orderByColumn;
        }
        this.prepareTableData();
      })
  }

  private getAllAffiliates(): void {
    this.coreService.setIsLoading(true);
    this.affiliateService.getAllAffiliates().pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllAffiliatesResponse) => {
      const affiliates = getAllAffiliatesResponse;

      affiliates.forEach(affiliate => {
        this.affiliateInputOptions.push({ label: affiliate.name, value: affiliate._id })
      });
    })
  }

  private getAllSuppliers(): void {
    this.coreService.setIsLoading(true);
    this.digitalLeadsSuppliersService.getAllDigitalLeadsSuppliers().pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllDigitalLeadsSuppliersResponse) => {
      const suppliers = getAllDigitalLeadsSuppliersResponse;

      suppliers.forEach(supplier => {
        this.supplierInputOptions.push({ label: supplier.name, value: supplier._id })
      });
    })
  }

  public onTypeSelectionChange(type: string): void {
    if ([ReportTypes.DigitalLeads].includes(type as ReportTypes)) {
      this.reportForm?.get?.('supplierId')?.enable();
    } else {
      this.reportForm?.get?.('supplierId')?.setValue('');
      this.reportForm?.get?.('supplierId')?.disable();
    }
  }

  private prepareTableData(): void {
    this.tableRowData = this.reports.map((report) => {
      const createdAtDate = new Date(report.createdAt);
      const formattedDate = format(new Date(createdAtDate), 'MM/dd/yyyy');

      return {
        _id: report._id,
        createdAt: formattedDate,
        period: report.period,
        user: report.user,
        affiliate: report.affiliate?.name || 'N/A',
        type: report.type || ReportTypes.DigitalLeads,
        actions: {
          download: true
        }
      };
    });
  }
}
