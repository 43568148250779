import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { finalize, Observable } from "rxjs";
import { format } from "date-fns";

import { CoreService } from "../../../core/services/core.service";
import { JobsService } from "../../../core/services/jobs.service";
import { Job } from "../../../core/types/jobs.type";
import { RowData } from "../../../shared/components/table/table.component";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { ToggleJobStateModalComponent } from "./toggle-job-state-modal/toggle-job-state-modal.component";

interface TableRowData {
  name: string;
  cron: string;
  lastRun: string;
  nextRun: string;
  state: SafeHtml;
  timezone: string;
}

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  @ViewChild(ToggleJobStateModalComponent) private toggleJobStateModalComponent: ToggleJobStateModalComponent;
  public isLoading: Observable<boolean>;
  public tableRowData: TableRowData[] = [];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['name', 'Name'],
    ['cron', 'Cron'],
    ['lastRun', 'Last Run'],
    ['nextRun', 'Next Run'],
    ['timezone', 'Timezone'],
    ['state', 'State']
  ]);
  public modalRef?: MatDialogRef<ModalComponent>;
  private jobs: Job[] = [];

  constructor(
    private coreService: CoreService,
    private jobsService: JobsService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getJobs();
    });
  }

  public handleToggleStateButton(row: RowData): void {
    const { actions, ...jobRow } = row;

    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      autoFocus: 'dialog',
      data: {
        title: `${actions.start ? 'Start' : 'Stop'} Job`,
        contentTemplate: this.toggleJobStateModalComponent.toggleJobStateModalContentTemplate,
        actionsTemplate: this.toggleJobStateModalComponent.toggleJobStateModalActionsTemplate,
        context: { ...jobRow, state: actions.start ? 'Running' : 'Stopped' }
      },
      restoreFocus: false
    });
  }

  public handleToggleJobStateModalConfirm(job: Job): void {
    const taskIndex = this.jobs.findIndex((jobToFind) => jobToFind.name === job.name);

    this.jobs[taskIndex] = job;

    this.setTableData();
    this.closeModal();
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private getJobs(): void {
    this.coreService.setIsLoading(true);
    this.jobsService
      .get()
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        next: (jobs) => {
          this.jobs = jobs;
        },
        complete: () => {
          this.setTableData();
        }
      })
  }

  private setTableData(): void {
    this.tableRowData = this.jobs.map((job) => {
      const statusColumnColor = job.state === 'Running' ? '#51C469' : '#FF4F4F';

      return {
        name: job.name,
        cron: job.cron,
        lastRun: job.lastRun ? format(new Date(job.lastRun), 'MM/dd/yyyy HH:mm:ss') : 'N/A',
        nextRun: job.nextRun ? format(new Date(job.nextRun), 'MM/dd/yyyy HH:mm:ss') : 'N/A',
        timezone: job.timezone,
        state:
          this.sanitizer.bypassSecurityTrustHtml(`<span style="color: ${statusColumnColor}">${job.state}</span>`),
        actions: {
          [job.state === 'Running' ? 'stop' : 'start']: true
        }
      };
    });
  }
}
