import { DigitalLeadsSupplier } from "./digital-leads.types";
import { Directmail } from "./directmail.types";
import { LandingPage } from "./landing-page.types";

export interface Submission {
  _id: string;
  landingPage: LandingPage;
  directmail?: Directmail;
  digitalLeadsSupplier?: DigitalLeadsSupplier;
  firstName: string;
  miidleName?: string;
  lastName: string;
  dateOfBirth?: string;
  email: string;
  address?: string;
  city: string;
  state: string;
  zipCode: string;
  loanAmount: number;
  cellPhone: string;
  createdAt: Date;
  updatedAt: Date;
  __v: string;
  dataSource: DataSources;
  remoteAddress?: string;
  socialSecurityNumber?: string;
  suffix?: Suffixes;
  monthlyIncome?: number;
  creditRating?: CreditRating;
  phone?: string;
  refCode?: string;
  mobilendId?: string;
  mobilendDecision?: LoanDecision;
  consents: Consents[];
}

export enum Consents {
  CreditPull = 'Credit Report',
  TCPA = 'TCPA',
  Terms = 'Terms',
}

export enum LoanDecision {
  PreQualified = 'Pre Qualified',
  Denied = 'Denied',
  Error = 'Error',
}

export enum DataSources {
  LandingPage = 'Landing Page',
  DigitalLeads = 'Digital Leads',
  Webhook = 'Webhook',
}

export enum CreditRating {
  Excellent = 'Excellent',
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor',
}

export enum Suffixes {
  Jr = 'JR',
  Sr = 'SR',
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
}

export interface GetSubmissionsResponse {
  submissions: Submission[];
  page: number;
  totalItems: number;
  limit: number;
  itemsPerPage: number;
  orderBy: string
  orderByColumn: string;
}

export enum SubmissionsOrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SubmissionsSortColumns {
  Id = '_id',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  RefCode = 'refCode',
  CreatedAt = 'createdAt',
}


export interface GetSubmissionsParams {
  campaign: string;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderByColumn?: string;
  search?: string;
  from?: string;
  to?: string;
}

export interface GetDownloadSubmissionsParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  from?: string;
  to?: string;
}


export enum SubmissionCampaigns {
  SafeStone = 'safestone',
  PointBreak = 'pointbreak',
  Skyline = 'skyline',
  SecureOne = 'secureone',
  LendMarc = 'lendmarc',
  Legacy = 'legacy',
  Prudent = 'prudent', 
  MyMobilend = 'mymobilend',
  YourMobilend = 'yourmobilend',
  GetMobilend = 'getmobilend',
  NewHaven = 'newhaven',
  MobilendLoan = 'mobilendloan',
  QuickStart = 'quickstart',
  SandStone = 'sandstone',
  ClearPath = 'clearpath',
  AmericanOne = 'americanone'
};

export interface ChartResponse {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: number;
      };
    };
  };
}

export enum ChartContext {
  Count = 'count',
  loanAmount = 'loanAmount',
}