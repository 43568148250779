import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

import { DocumentToBeRendered, FileEvent } from "../../../../shared/components/upload-documents/upload-documents.component";
import { UsersService } from "../../../../core/services/users.service";
import { Affiliate } from "../../../../core/types/affiliate.types";
import { CoreService } from "../../../../core/services/core.service";

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-import-users-modal',
  templateUrl: './import-users-modal.component.html'
})
export class ImportUsersModalComponent implements OnChanges {
  @Input() public affiliates: Affiliate[];
  @ViewChild('importUsersModalContentTemplate')
  public importUsersModalContentTemplate: TemplateRef<any>;
  @ViewChild('importUsersModalActionsTemplate')
  public importUsersModalActionsTemplate: TemplateRef<any>;
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public confirm: EventEmitter<string> = new EventEmitter();
  public documentsToBeUploaded: DocumentToBeRendered[] = [
    {
      type: 'users_file',
      label: 'Users',
      description: 'Upload users CSV file (up to 6 MB in size).',
      uploadedFiles: []
    },
  ];
  public affiliateInputOptions: SelectOption[] = [];
  public affiliate = new FormControl('', Validators.required);

  constructor(
    private toastrService: ToastrService,
    private usersService: UsersService,
    private coreService: CoreService
  ) {}

  public get disableContinueButton(): boolean {
    const allDocumentsHaveAtLeastOneUploadedFile = this.documentsToBeUploaded
      .every((document) => document.uploadedFiles.length);

      if (!allDocumentsHaveAtLeastOneUploadedFile || !this.affiliate.valid) {
        return true;
      }
      return false;
  }

  public ngOnChanges(): void {
    this.affiliateInputOptions = this.affiliates.map((affiliate) => ({
      label: affiliate.name,
      value: affiliate._id
    }));
  }

  public handleFileAction(fileEvent: FileEvent): void {
    if (fileEvent.error) {
      this.toastrService.error(fileEvent.error, undefined, { positionClass: 'toast-custom-bottom-center' });
    } else {
      const correspondingDocument = this.documentsToBeUploaded.find(
        (documentToBeUploaded) => documentToBeUploaded.type === fileEvent.documentKey
      )!;

      if (fileEvent.action === 'upload') {
        fileEvent.uploadedFiles.forEach(file => {
          correspondingDocument.uploadedFiles.push({
            ...file,
            displayTrashButton: true
          })
        })
      } else {
        const fileToBeDeletedIdx = correspondingDocument.uploadedFiles.findIndex((uploadedFile) => uploadedFile.id === fileEvent.uploadedFiles[0].id);
  
        correspondingDocument.uploadedFiles.splice(fileToBeDeletedIdx, 1);
      }
    }
  }

  public handleCancelButtonClick(): void {
    this.affiliate.reset();
    this.cancel.emit();
  }

  public handleConfirmButtonClick(): void {
    this.affiliate.markAsTouched();

    if (this.affiliate.valid && this.documentsToBeUploaded[0].uploadedFiles.length) {
      this.coreService.setIsLoading(true);
      this.usersService.importUsers(
        this.affiliate.value!,
        {
          documentType: this.documentsToBeUploaded[0].type,
          files: this.documentsToBeUploaded[0].uploadedFiles.map((uploadedFile) => uploadedFile.file)
        }
      ).pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      ).subscribe(() => {
        this.toastrService.success(
          'Users successfully imported',
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
        this.confirm.emit();
      });
    }
  }
}
