<app-card class="p-4">
  <form [formGroup]="landingPageForm">
    <mat-form-field class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Campaign</mat-label>
      <input matInput formControlName="campaign" />
      <mat-error>
        {{ getErrorMessage('campaign', 'Campaign') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appCommaSeparatedValidator class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Domains</mat-label>
      <input matInput formControlName="domains" />
      <mat-error>
        {{ getErrorMessage('domains', 'Domains') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appCommaSeparatedValidator class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Emails</mat-label>
      <input matInput formControlName="emails" />
      <mat-error>
        {{ getErrorMessage('emails', 'Emails') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
    class="w-100"
    hideRequiredMarker
    appearance="outline"
  >
    <mat-label>Affiliate</mat-label>
    <mat-select formControlName="affiliate">
      <mat-option *ngFor="let option of affiliateInputOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{ getErrorMessage('affiliate', 'Affiliate') }}
    </mat-error>
  </mat-form-field>
    <div class="
      d-flex
      align-items-center
      justify-content-end
      gap-4
      py-3
    ">
      <div class="d-flex gap-4">
        <button
        mat-button
        color="primary"
        (click)="handleBackButtonClick()"
      >
        Back
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="landingPageForm.invalid"
        (click)="handleContinueButtonClick()"
      >
        Save
      </button>
      </div>
    </div>
  </form>
</app-card>
<router-outlet></router-outlet>