import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

export interface DocumentToBeRendered {
  description: string;
  uploadedFiles: UploadedFile[];
  type: string;
  label: string;
}
export interface FileEvent {
  action: 'upload' | 'delete';
  documentKey: string;
  error?: string | null; 
  uploadedFiles: UploadedFile[];
}
export interface UploadedFile {
  displayDownloadButton?: boolean;
  displayTrashButton?: boolean;
  file: File;
  id?: number;
  status?: string;
  rejectReason?: string;
}

export interface GeneratedFiles {
  files: UploadedFile[];
  error?: string;
}

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  @Input() public documentsToBeRendered: DocumentToBeRendered[] = [];
  @Input() public disabledDropZone: boolean = false;
  @Input() public maxFileSize: number;
  @Input() public allowedFileTypes: string[];
  @Output() public onFileUpload: EventEmitter<FileEvent> = new EventEmitter();
  @Output() public onFileDelete: EventEmitter<FileEvent> = new EventEmitter();
  @Output() public onFileDownload: EventEmitter<UploadedFile> = new EventEmitter();
  public documentsToBeRenderedMap: Map<string, DocumentToBeRendered> = new Map();

  constructor() {}

  public ngOnInit (): void {
    this.documentsToBeRendered.forEach((document) => {
      this.documentsToBeRenderedMap.set(
        document.type,
        {
          description: document.description,
          uploadedFiles: document.uploadedFiles,
          type: document.type,
          label: document.label
        }
      );
    });
  }

  public keepDocumentsToBeRenderedMapOrder(): number {
    return 0;
  }

  public handleFileDrop(droppedFiles: NgxFileDropEntry[], documentKey: string): void {
    const { files, error } = this.generateNewUploadedFiles(droppedFiles);

    const event: FileEvent = { action: 'upload', documentKey, uploadedFiles: files };

    if (error) {
      event.error = error;
    }

    this.onFileUpload.emit(event);
  }

  public downloadFile(uploadedFile: UploadedFile): void {
    this.onFileDownload.emit(uploadedFile);
  }

  public deleteFile(documentKey: string, uploadedFileToDelete: UploadedFile): void {
    this.onFileDelete.emit({
      action: 'delete',
      documentKey,
      uploadedFiles: [uploadedFileToDelete],
    });
  }

  private generateNewUploadedFiles(droppedFiles: NgxFileDropEntry[]): GeneratedFiles {

    const newUploadedFiles: UploadedFile[] = [];
    const filteredFiles = droppedFiles.filter(this.isValidFileType.bind(this));

    const generatedFiles: GeneratedFiles = { files: newUploadedFiles };

    if (filteredFiles.length) {
      const fileEntry = filteredFiles[0].fileEntry as FileSystemFileEntry;

      fileEntry.file((file) => {
        if (this.isValidFileSize(file)) {
          const newUploadedFile = { file, id: Date.now() };
          newUploadedFiles.push(newUploadedFile);
        } else {
          generatedFiles.error = `Validation failed (expected size is less or equal to ${this.maxFileSize} megabytes)`;
        }
      });
    } else {
      generatedFiles.error = `Validation failed (allowed file types are: ${this.allowedFileTypes.map(type => type)})`;
    }

    return generatedFiles;
  }

  private isValidFileSize(file: File): boolean {
    return file.size <= this.maxFileSize; 
  }

  private isValidFileType(fileDropEntry: NgxFileDropEntry): boolean {
    const { fileEntry } = fileDropEntry;
    const regex = /\.[^\.]+$/;
    const execResult = regex.exec(fileEntry.name);
    const isValidFile = Boolean(execResult && this.allowedFileTypes.includes(execResult[0]));

    return isValidFile;
  }
}
