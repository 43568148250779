<div>
  <h1 class="fw-bold">Import Digital Leads</h1>
</div>
<form [formGroup]="importDigitalLeadsForm">
  <mat-form-field
    class="w-100 py-3"
    hideRequiredMarker
    appearance="outline"
  >
    <mat-label>Supplier</mat-label>
    <mat-select formControlName="supplier">
      <mat-option *ngFor="let option of supplierInputOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{ getErrorMessage('supplier', 'Supplier') }}
    </mat-error>
  </mat-form-field>
  <app-upload-documents
    [documentsToBeRendered]="documentsToBeUploaded"
    [maxFileSize]="6000000"
    [allowedFileTypes]="['.csv']"
    [disabledDropZone]="documentsToBeUploaded[0].uploadedFiles.length > 0"
    (onFileUpload)="handleFileAction($event)"
    (onFileDelete)="handleFileAction($event)"
  ></app-upload-documents>
  <div class="
    d-flex
    gap-3
    justify-content-center
    justify-content-lg-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleModalClose()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="disableContinueButton"
      (click)="handleContinueButtonClick()"
    >
      Continue
    </button>
  </div>
</form>