import { Component, OnInit } from "@angular/core";
import { AmplifyUser } from '@aws-amplify/ui';

import { AuthService } from "../../../core/services/auth.service";
import { LandingPageService } from "../../../core/services/landing-page.service";
import { Roles } from "../../../core/types/role.type";

interface NavItem {
  allowedRoles: string[];
  icon: string;
  label: string;
  campaign?: string;
  url?: string;
  expanded?: boolean;
  subMenu?: SubNavItem[];
}

interface SubNavItem {
  allowedRoles: string[];
  icon: string;
  label: string;
  campaign?: string;
  url: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public loggedUser: AmplifyUser;
  public navItems: NavItem[] = [
    {
      icon: 'insert_chart',
      label: 'Dashboard',
      url: '/admin/dashboard',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'create_new_folder',
      label: 'Leads',
      expanded: false,
      subMenu: [],
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'folder_special',
      label: 'Digital Leads',
      expanded: false,
      subMenu: [
        {
          icon: 'group',
          label: 'Available Leads',
          url: '/admin/digital-leads',
          allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
        },
        {
          icon: 'settings_account_box',
          label: 'Affiliate Settings',
          url: '/admin/affiliate-digital-lead-settings',
          allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
        },
        {
          icon: 'local_gas_station',
          label: 'Suppliers',
          url: '/admin/digital-leads-suppliers',
          allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
        }
      ],
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'supervised_user_circle',
      label: 'Affiliates',
      url: '/admin/affiliates',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'network_locked',
      label: 'Users',
      url: '/admin/users',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'picture_in_picture',
      label: 'Landing Pages',
      url: '/admin/landing-pages',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'note_add',
      label: 'Directmail Imports',
      url: '/admin/directmail-imports',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'get_app',
      label: 'Reports',
      url: '/admin/reports',
      allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
    },
    {
      icon: 'schedule',
      label: 'Jobs',
      url: '/admin/jobs',
      allowedRoles: [Roles.SuperAdmin]
    }
  ];

  constructor(
    private authService: AuthService,
    private landingPageService: LandingPageService,
  ) {}

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser()!;

    setTimeout(() => {
      this.getAllCampaigns();
    });
  }

  public shouldRenderNavItem(item: NavItem | SubNavItem): boolean {
    return this.loggedUser && item.allowedRoles.includes(this.loggedUser.attributes!["custom:role"]);
  }

  private getAllCampaigns(): void {
    this.landingPageService.getAllLandingPages().subscribe((getAllCampaignsResponse) => {
      const landingPages = getAllCampaignsResponse;
      const submissionsNavIndex = this.navItems.findIndex(nav => nav.label === 'Leads');

      landingPages.forEach(landingPage => {
        this.navItems[submissionsNavIndex].subMenu!.push({ 
          label: landingPage.campaign,
          campaign: landingPage.campaign,
          icon: 'create_new_folder',
          url: `/admin/leads/${landingPage.campaign}`,
          allowedRoles: [Roles.SuperAdmin, Roles.Admin, Roles.CustomerService]
        })
      });
    });
  }
}
