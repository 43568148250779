import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";

import { AuthService } from "../../core/services/auth.service";
import { AuthenticatedUser } from "../../core/types/auth.types";
import { Roles } from "../../core/types/role.type";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public pageLabel: string = '';
  public isMobile: boolean;
  public loggedUser: AuthenticatedUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    this.isMobile = event.target.innerWidth < 576;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();
    this.isMobile = window.innerWidth < 576;

    this.checkIfUserIsAllowedToAccessPortal();
    this.setPageLabel();
  }

  private checkIfUserIsAllowedToAccessPortal(): void {
    const allowedRoles = [Roles.Admin, Roles.SuperAdmin, Roles.CustomerService];

    if (!allowedRoles.includes(this.loggedUser.attributes["custom:role"] as Roles)) {
      this.authService.logout();
      this.router.navigate(['/auth']);
    }
  }

  private setPageLabel(): void {
    this.pageLabel = this.route.firstChild!.snapshot.data['label'];

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.pageLabel = this.route.firstChild!.snapshot.data['label'];
      });
  }
}
