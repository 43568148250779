<app-card class="p-4 affiliate-digital-lead-settings-list">
  <div class="d-flex align-items-center justify-content-between">
    <h2>Digital Lead Settings</h2>
    <button
      *appHasRole="[roles.SuperAdmin]"
      mat-raised-button
      color="primary"
      (click)="handleAddSettingButtonClick()"
    >
      Add Setting
    </button>
  </div>

  <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">
    No data received
  </h3>

  <app-table
    *ngIf="tableRowData.length"
    [disabledSortColumns]="['states']"
    [clickableRow]="true"
    [columnsHeadersMap]="tableColumnsAndHeaders"
    [paginatorConfig]="paginatorConfig"
    [rowData]="tableRowData"
    [disableActiveInput]="disableActiveInput"
    (onPageClick)="handlePageClick($event)"
    (onRowClick)="handleTableRowClick($event)"
    (onSortClick)="handleSortClick($event)"
    (onToggleClick)="handleToggleClick($event)"
  ></app-table>
</app-card>

<app-activate-deactivate-affiliate-digital-lead-setting-modal
  (cancel)="handleActivateDeactivateSettingModalCancelButtonClick($event)"
  (confirm)="closeModal()"
></app-activate-deactivate-affiliate-digital-lead-setting-modal>