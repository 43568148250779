import { Component, Input, OnChanges } from "@angular/core";
import { Observable } from "rxjs";

import { CoreService } from "../../../../core/services/core.service";
import { Submission } from "../../../../core/types/submission.types";
import { Affiliate } from "../../../../core/types/affiliate.types";

@Component({
  selector: 'app-consumer-information-tab',
  templateUrl: './consumer-information-tab.component.html',
  styleUrls: ['./consumer-information-tab.component.scss']
})
export class ConsumerInformationTabComponent implements OnChanges {
  @Input() public submission?: Submission;
  public affiliate: Affiliate;
  public isLoading: Observable<boolean>;

  constructor(private coreService: CoreService) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnChanges(): void {
    if (this.submission) {
      this.affiliate = this.submission.landingPage.affiliate as Affiliate;
    }
  }
}
