<div class="container submissions-list">
  <app-card class="d-flex flex-column h-100 p-4">
    <div class="d-flex align-items-center justify-content-around justify-content-sm-between">
      <h2 class="mb-0 text-capitalize d-none d-sm-block">{{ tableHeader }}</h2>
      <div class="d-flex align-items-center gap-3">
        <button
          *appHasRole="[roles.SuperAdmin]"
          mat-raised-button
          color="primary"
          [disabled]="!digitalLeadsSelected.length"
          (click)="handleSendToCampaignButtonClick()"
        >
          Send to Campaign
        </button>
        <button
          *appHasRole="[roles.SuperAdmin]"
          mat-raised-button
          color="primary"
          (click)="handleImportButtonClick()"
        >
          Import
        </button>
      </div>
    </div>    
    <mat-form-field> 
      <input matInput placeholder="Search Lead" #input>
    </mat-form-field>
    <div class="list-container">
      <span class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">No data received</span>
      <app-table
        *ngIf="tableRowData.length"
        [clickableRow]="true"
        [columnsHeadersMap]="tableColumnsAndHeaders"
        [rowData]="tableRowData"
        [paginatorConfig]="paginatorConfig"
        (onRowClick)="handleTableRowClick($event)"
        (onPageClick)="handlePageClick($event)"
        (onSortClick)="handleSortClick($event)"
        (onCheckBoxClick)="handleCheckBoxRowClick($event)"
      ></app-table>
    </div>
  </app-card>
</div>

<ng-container>
  <ng-template #sendToCampaignModalActionsTemplate>
    <app-send-leads-to-campaign 
      (onAction)="handleSendLeadsToCampaignAction()"
      [digitalLeadsSelected]="digitalLeadsSelected"
      [modalRef]="modalRef">
    </app-send-leads-to-campaign>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template #fileUploadModalActionsTemplate>
    <app-import-leads
      (onAction)="handleImportLeadsAction()" 
      [modalRef]="modalRef">
    </app-import-leads>
  </ng-template>
</ng-container>
