import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, tap } from "rxjs";
import { format } from "date-fns";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { RowData, SortConfigOptions, ToggleEvent } from "../../../shared/components/table/table.component";
import { CoreService } from "../../../core/services/core.service";
import { PaginatorConfigOptions } from "../../../shared/components/table/table.component";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { LandingPageService } from '../../../core/services/landing-page.service';
import { LandingPage, LandingPagesListPageParams } from '../../../core/types/landing-page.types';
import { Roles } from '../../../core/types/role.type';
import { Affiliate } from '../../../core/types/affiliate.types';

interface TableRowData {
  _id: string;
  affiliate: string;
  domains: string[];
  emails: string[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.scss']
})
export class LandingPagesComponent implements OnInit {
  @ViewChild('activeAndDesactiveModalContentTemplate')
  private activeAndDesactiveModalContentTemplate: TemplateRef<any>;
  @ViewChild('activeAndDesactiveModalActionsTemplate')
  private activeAndDesactiveModalActionsTemplate: TemplateRef<any>;
  @ViewChild('input')
  public input: ElementRef;
  public roles: typeof Roles = Roles;
  public isLoading: Observable<boolean>;
  public modalRef?: MatDialogRef<ModalComponent>;
  public paginatorConfig: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: 'DESC',
    orderByColumn: '_id'
  };
  public landingPages: LandingPage[];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['affiliate', 'Affiliate'],
    ['domains', 'Domains'],
    ['campaign', 'Campaign'],
    ['emails', 'Emails'],
    ['createdAt', 'Date Created'],
    ['updatedAt', 'Date Updated'],
  ]);
  public tableRowData: TableRowData[] = [];
  public disabledSortColumns: string[] = ['affiliate', 'domains', 'emails'];

  constructor(
    public router: Router,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private landingPageService: LandingPageService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getAllLandingPages();
    });
  }

  public ngAfterViewInit(): void {
    if (this?.input?.nativeElement) {
      fromEvent(this.input.nativeElement,'keyup')
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(async () => {
              this.getLandingPagesData({
                page: 0, 
                pageSize: this.paginatorConfig.pageSize!,
                orderByColumn: this.sortConfig.orderByColumn,
                orderBy: this.sortConfig.orderBy,
                search: this.input.nativeElement.value
              });
            })
        )
        .subscribe();
    }
  } 

  public handleTableRowClick(row: RowData): void {
    this.router.navigate(
      ['../landing-pages/creation', row['_id']],
      { relativeTo: this.route }
    );
  }

  public handlePageClick(event: PageEvent): void {
    this.getLandingPagesData({
      page: event.pageIndex, 
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getLandingPagesData({
      page: 0,
      pageSize: this.paginatorConfig.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction
    });
  }

  public handleToggleClick({ event, row }: ToggleEvent): void {
    const landingPage = row as TableRowData;

    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        title: 'Active/Deactivate Landing Page',
        contentTemplate: this.activeAndDesactiveModalContentTemplate,
        actionsTemplate: this.activeAndDesactiveModalActionsTemplate,
        context: { landingPage: landingPage, action: event.checked }
      },
      restoreFocus: false
    });
  }

  public handleModalToggleActionConfirmationButtonClick({ landingPage, action }: { landingPage: TableRowData, action: boolean }): void {
    this.coreService.setIsLoading(true);
    this.landingPageService.editLandingPage(
      landingPage._id, { active: action }
    ).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
      ).subscribe(() => {
        this.modalRef!.close();
        this.modalRef = undefined;
        this.toastrService.success(
          `Landing Page has been successfully ${action ? 'activated' : 'deactivated'}`, 
          undefined, 
          { positionClass: 'toast-custom-bottom-center' }
        );
      })
  }

  public handleModalToggleActionCancelButtonClick({ landingPage, action }: { landingPage: TableRowData, action: boolean }): void {
    const affiliateIndex = this.tableRowData.findIndex(customer => customer._id === landingPage._id);
    
    this.tableRowData[affiliateIndex].active = !action;
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private getAllLandingPages(): void {
    this.coreService.setIsLoading(true);
    this.landingPageService.getAllLandingPagesPaginate({
      page: this.paginatorConfig.pageIndex!, 
      pageSize: this.paginatorConfig.pageSize!
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllLandingPagesResponse) => {
      const { landingPages, ...pageData } = getAllLandingPagesResponse;
      this.landingPages = landingPages;
      this.paginatorConfig.length = pageData.totalItems;
      this.prepareTableData();
    })
  }

  public getLandingPagesData(params: LandingPagesListPageParams): void {
    this.coreService.setIsLoading(true);
    this.landingPageService.getAllLandingPagesPaginate({
      search: params.search,
      page: params.page || 0,
      pageSize: params.pageSize || 10,
      orderByColumn: params.orderByColumn || '_id',
      orderBy: params.orderBy || 'DESC',
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllLandingPagesResponse) => {
      const { landingPages, ...pageData } = getAllLandingPagesResponse;
      this.landingPages = landingPages;
      this.paginatorConfig.length = pageData.totalItems;
      this.paginatorConfig.pageIndex = pageData.page;
      this.paginatorConfig.pageSize = pageData.itemsPerPage;
      this.sortConfig.orderBy = pageData.orderBy;
      this.sortConfig.orderByColumn = pageData.orderByColumn;
      this.prepareTableData();
    })
  }

  public handleAddNewAffiliateButtonClick(): void {
    this.router.navigate(
      ["../landing-pages/creation"],
      { relativeTo: this.route }
    );
  }

  private prepareTableData(): void {
    this.tableRowData = this.landingPages.map((landingPage) => {
      const createdAt = this.formateDate(landingPage.createdAt);
      const updatedAt = this.formateDate(landingPage.updatedAt);

      return {
        _id: landingPage._id,
        affiliate: (landingPage.affiliate as Affiliate).name,
        campaign: landingPage.campaign,
        domains: landingPage.domains,
        emails: landingPage.emailList,
        active: landingPage.active,
        createdAt,
        updatedAt
      };
    });
  }

  private formateDate(date: Date): string {
    const newDate = new Date(date);
    return format(new Date(newDate), 'MM/dd/yyyy')
  }
}
