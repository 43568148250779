<span class="mt-3" *ngIf="!(isLoading | async) && !digitalLead">
  No data received
</span>

<ng-container *ngIf="digitalLead">
  <div class="data-container flex-column flex-sm-row gap-sm-15">
    <span class="data-label">ID</span>
    <span class="data-value">{{ digitalLead._id }}</span>
  </div>
  <div *ngIf="digitalLead.digitalLeadsSupplier" class="data-container flex-column flex-sm-row gap-sm-15">
    <span class="data-label">Supplier</span>
    <span class="data-value">{{ digitalLead.digitalLeadsSupplier.name }}</span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15
  ">
    <span class="data-label">Requested Amount</span>
    <span>
      {{ digitalLead.loanAmount | currency:'USD':'symbol':'1.2-2' }}
    </span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15"
  >
    <span class="data-label">Name</span>
    <span>
      {{ digitalLead.firstName }} {{ digitalLead.lastName }}
    </span>
  </div>
  <div
    *ngIf="digitalLead.suffix" 
    class="
      data-container 
      flex-column 
      flex-sm-row 
      gap-sm-15"
  >
    <span class="data-label">Suffix</span>
    <span class="text-break">{{ digitalLead.suffix }}</span>
  </div>  
  <div class="
    data-container 
    flex-column 
    flex-sm-row 
    gap-sm-15"
  >
    <span class="data-label">Email Address</span>
    <span class="text-break">{{ digitalLead.email }}</span>
  </div>
  <div
    *ngIf="digitalLead.phone"
    class="
      data-container 
      flex-column 
      flex-sm-row
      gap-sm-15"
  >
    <span class="data-label">Phone</span>
    <span class="text-break">{{ digitalLead.phone | mask:'(000) 000-0000' }}</span>
  </div>  
  <div class="
    data-container 
    flex-column 
    flex-sm-row
    gap-sm-15"
  >
    <span class="data-label">Mobile Phone</span>
    <span class="text-break">{{ digitalLead.cellPhone | mask:'(000) 000-0000' }}</span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15
  ">
    <div class="data-label">
      <span>Address</span>
    </div>
    <div class="d-flex flex-column gap-2">
      <span>{{ digitalLead.address }}</span>
      <span>{{ digitalLead.city }}</span>
      <span>{{ digitalLead.zipCode }}</span>
      <span>{{ digitalLead.state }}</span>
    </div>
  </div>
  <div 
    *ngIf="digitalLead.dateOfBirth" 
    class="
      data-container
      flex-column
      flex-sm-row
      gap-sm-15
    ">
    <span class="data-label">Date of Birth</span>
    <span>{{ digitalLead.dateOfBirth | date:'MM/dd/YYYY' }}</span>
  </div>
  <div
    class="
      data-container
      flex-column
      flex-sm-row
      gap-sm-15
    "
  >
    <span class="data-label">Registered Date</span>
    <span>{{ digitalLead.createdAt | date:'MM/dd/YYYY' }}</span>
  </div>
  <div 
    *ngIf="digitalLead.monthlyIncome"
    class="
      data-container 
      flex-column 
      flex-sm-row 
      gap-sm-10 
      gap-md-13 
      gap-lg-15
    "
  >
    <span class="data-label">Monthly Income</span>
    <span>
      {{ digitalLead.monthlyIncome | currency:'USD':'symbol':'1.2-2' }}
    </span>
  </div>
</ng-container>