<app-card class="jobs-list">
  <h2>Jobs</h2>
  <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">
    No data received
  </h3>
  <app-table
    *ngIf="tableRowData.length"
    [columnsHeadersMap]="tableColumnsAndHeaders"
    [rowData]="tableRowData"
    (onStartButtonClick)="handleToggleStateButton($event)"
    (onStopButtonClick)="handleToggleStateButton($event)"
  ></app-table>
</app-card>

<app-toggle-job-state-modal
  (cancel)="closeModal()"
  (confirm)="handleToggleJobStateModalConfirm($event)"
></app-toggle-job-state-modal>