<ng-template #deleteUserModalContentTemplate>
  <span>Are you sure you want to delete this user?</span>
</ng-template>
<ng-template #deleteUserModalActionsTemplate let-modalData>
  <div class="
    d-flex
    align-items-center
    gap-3
    justify-content-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick()"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleModalDeleteConfirmationButtonClick(modalData.userId)"
    >
      Delete
    </button>
  </div>
</ng-template>