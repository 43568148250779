import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize, lastValueFrom } from 'rxjs';

import { AffiliateService } from '../../../../core/services/affiliate.service';
import { CoreService } from '../../../../core/services/core.service';
import { Affiliate, CreateAffiliatePayload } from '../../../../core/types/affiliate.types';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { AuthenticatedUser } from '../../../../core/types/auth.types';
import { AuthService } from '../../../../core/services/auth.service';
import { Roles } from '../../../../core/types/role.type';

@Component({
  selector: 'app-affiliates-creation-edition',
  templateUrl: './affiliates-creation-edition.component.html'
})
export class AffiliatesCreationEditionComponent implements OnInit {
  public affiliate: Affiliate
  public affiliateId: string
  public selected: string | undefined
  public affiliateForm: FormGroup;
  public modalRef?: MatDialogRef<ModalComponent>;
  public isInputVisible: boolean = false;
  public loggedUser: AuthenticatedUser;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private affiliateService: AffiliateService,
    private authService: AuthService,
    private coreService: CoreService,
    private toastrService: ToastrService
  ) { 
    this.affiliateForm = this.fb.group({
      name: ['', Validators.required],
      campaign: [''],
      mobilendApiKey: ['', Validators.required]
    });
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();
    this.affiliateId = this.route.snapshot.params['id'];

    setTimeout(() => {
      if (!!this.affiliateId) {
        this.getAffiliate();
      }
    })
  }

  public getErrorMessage(controlName: keyof typeof this.affiliateForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.affiliateForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  private getAffiliate(): void {
    this.coreService.setIsLoading(true);
    this.affiliateService
      .getAffiliate(this.affiliateId)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((getAffiliateResponse) => {
        this.affiliate = getAffiliateResponse;
        this.affiliateForm.patchValue({
          name: this.affiliate.name,
          mobilendApiKey: this.affiliate.mobilendApiKey       
        })

        if (this.loggedUser.attributes['custom:role'] !== Roles.SuperAdmin) {
          this.affiliateForm.controls['mobilendApiKey'].disable();
        }
      })

  }

  public handleBackButtonClick(): void {
    const routePath = this.affiliateId ? '../../../affiliates' : '../../affiliates';
    this.router.navigate(
      [routePath],
      { relativeTo: this.route }
    );
  }

  public async handleContinueButtonClick(): Promise<void> {
    this.affiliateForm.markAllAsTouched();

    if (this.affiliateForm.valid) {
      try {
        this.coreService.setIsLoading(true);
        const affiliatePayload = this.affiliateForm.value as CreateAffiliatePayload;
  
        if (!!this.affiliateId) {
          await lastValueFrom(this.affiliateService.editAffiliate(this.affiliateId, affiliatePayload))
        } else {
          await lastValueFrom(this.affiliateService.createAffiliate(affiliatePayload))
        }
  
        const routePath = this.affiliateId ? '../../../affiliates' : '../../affiliates';
  
        this.router.navigate(
          [routePath],
          { relativeTo: this.route }
        );
        const successMessage = `Affiliate was ${this.affiliateId ? 'updated' : 'created'} successfully`
        this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
  
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public toggleVisibility(): void {
    this.isInputVisible = !this.isInputVisible;
  }

  public getSecurityInputClass(): string {
    return this.isInputVisible ? 'input-security-none' : 'input-security-disc';
  }
}
