import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { CreateUserPayload, AdminUpdateUserPayload, User } from "../types/user.types";
import { environment } from "../../../environments/environment";
import { CreateUploadedDocument } from "../types/directmail.types";

@Injectable({ providedIn: 'root' })
export class UsersService {
  private apiUrl = `${environment.apiUrl}/users`;

  constructor(private http: HttpClient) {}

  public createUser(data: CreateUserPayload): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}`, data, { withCredentials: true });
  }

  public importUsers(affiliateId: string, document: CreateUploadedDocument): Observable<void> {
    const formData = new FormData();

    formData.append('affiliateId', affiliateId);

    document.files.forEach((file) => {
      formData.append(document.documentType, file);
    });

    return this.http.post<void>(`${this.apiUrl}/import`, formData);
  }

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiUrl}/all`, { withCredentials: true });
  }

  public adminUpdateUser(userId: string, updateUserPayload: AdminUpdateUserPayload): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/${userId}`, updateUserPayload, { withCredentials: true });
  }

  public deleteUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${userId}`, { withCredentials: true });
  }

  public findUser(userId: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${userId}`, { withCredentials: true });
  }
}
