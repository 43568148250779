<app-card class="p-4">
  <form
    class="d-flex flex-column"
    [formGroup]="userForm"
    (submit)="handleUserFormSubmit()"
  >
    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error>
        {{ getErrorMessage('name', 'Name') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error>
        {{ getErrorMessage('email', 'Email') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let option of roleInputOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ getErrorMessage('role', 'Role') }}
      </mat-error>
    </mat-form-field>
    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Affiliate</mat-label>
      <mat-select formControlName="affiliate">
        <mat-option *ngFor="let option of affiliateInputOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ getErrorMessage('affiliate', 'Affiliate') }}
      </mat-error>
    </mat-form-field>
    <div class="
      mt-4
      d-flex align-items-center
      justify-content-end
      gap-4
    ">
      <button
        mat-stroked-button
        type="button"
        color="primary"
        (click)="handleBackButtonClick()"
      >
        Back
      </button>
      <button 
        mat-raised-button
        type="submit"
        [disabled]="userForm.invalid"
        color="primary"
      >
        Save
      </button>
    </div>
  </form>
</app-card>