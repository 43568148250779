import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

import { Job } from "../../../../core/types/jobs.type";
import { CoreService } from "../../../../core/services/core.service";
import { JobsService } from "../../../../core/services/jobs.service";

@Component({
  selector: 'app-toggle-job-state-modal',
  templateUrl: './toggle-job-state-modal.component.html'
})
export class ToggleJobStateModalComponent {
  @ViewChild('toggleJobStateModalContentTemplate') public toggleJobStateModalContentTemplate: TemplateRef<any>;
  @ViewChild('toggleJobStateModalActionsTemplate') public toggleJobStateModalActionsTemplate: TemplateRef<any>;
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public confirm: EventEmitter<Job> = new EventEmitter();

  constructor(
    private coreService: CoreService,
    private jobsService: JobsService,
    private toastrService: ToastrService
  ) {}

  public handleCancelButtonClick(): void {
    this.cancel.emit();
  }

  public handleConfirmButtonClick(job: Job): void {
    this.coreService.setIsLoading(true);
    this.jobsService.toggleState(
      job.name,
      { action: job.state === 'Running' ? 'start' : 'stop' }
    )
    .pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    )
    .subscribe({
      next: (task) => {
        const action = task.state === 'Running' ? 'started' : 'stopped';

        this.confirm.emit(task);
        this.toastrService.success(
          `Job has been successfully ${action}`,
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
      }
    });
  }
}
