import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

import { CoreService } from "../../../../core/services/core.service";
import { UsersService } from "../../../../core/services/users.service";

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html'
})
export class DeleteUserModalComponent {
  @ViewChild('deleteUserModalContentTemplate')
  public deleteUserModalContentTemplate: TemplateRef<any>;
  @ViewChild('deleteUserModalActionsTemplate')
  public deleteUserModalActionsTemplate: TemplateRef<any>;
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public confirm: EventEmitter<string> = new EventEmitter();

  constructor (
    private coreService: CoreService,
    private usersService: UsersService,
    private toastrService: ToastrService
  ) {}

  public handleCancelButtonClick(): void {
    this.cancel.emit();
  }

  public handleModalDeleteConfirmationButtonClick(userId: string): void {
    this.coreService.setIsLoading(true);
    this.usersService
      .deleteUser(userId)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        next: () => {
          this.confirm.emit(userId);
          this.toastrService.success(
            "User has been successfully deleted", 
            undefined, 
            { positionClass: 'toast-custom-bottom-center' }
          );
        },
        error: (error) => {
          this.cancel.emit();
          this.toastrService.error(
            error,
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
  }
}
