import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from "rxjs";

import { SubmissionDetailsTabs, CoreService } from "../../../../core/services/core.service";
import { DigitalLead } from "../../../../core/types/digital-leads.types";
import { DigitalLeadsService } from "../../../../core/services/digital-leads.service";

interface DigitalLeadDetail {
  name: SubmissionDetailsTabs;
  id: string;
}
export interface DigitalLeadTabs {
  userInformation: DigitalLeadDetail;
}

@Component({
  selector: 'app-digital-lead-details',
  templateUrl: './digital-lead-details.component.html',
  styleUrls: ['./digital-lead-details.component.scss']
})
export class DigitalLeadDetailsComponent implements OnInit {
  public tabs: DigitalLeadTabs = {
    userInformation: {
      name: SubmissionDetailsTabs.UserInformation,
      id: 'user-information'
    },
  };
  public tabsNames: typeof SubmissionDetailsTabs = SubmissionDetailsTabs;
  public currentTab: SubmissionDetailsTabs = SubmissionDetailsTabs.UserInformation;
  public tabComponentSelectorMap: Map<SubmissionDetailsTabs, string>;
  public digitalLead?: DigitalLead;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private coreService: CoreService,
    private digitalLeadsService: DigitalLeadsService,
  ) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.findDigitalLead();
    });
  }

  public handleBackButtonClick() {
    this.router.navigate(['..']);
  }

  public keepTabsOrder(): number {
    return 0;
  }

  public handleTabClick(tab: SubmissionDetailsTabs, button: EventTarget | null): void {
    this.currentTab = tab;
    this.coreService.setSubmissionDetailsCurrentTab(tab);

    const buttonElement = button as HTMLButtonElement;
    buttonElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  private findDigitalLead(): void {
    const digitalLeadId = this.route.snapshot.params['id'];

    this.coreService.setIsLoading(true);
    this.digitalLeadsService
      .getDigitalLead(digitalLeadId)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((findDigitalLeadResponse) => {
        this.digitalLead = findDigitalLeadResponse;
      });
  }
}
