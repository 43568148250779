import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

export interface RowData {
  [key: string]: any
}

export interface PaginatorConfigOptions {
  length?: number
  pageSize?: number
  pageIndex?: number
  pageSizeOptions: number[] | readonly number[];
  showFirstLastButtons?: boolean
}

export interface SortConfigOptions {
  orderByColumn?: string
  orderBy?: string
}

export interface ToggleEvent {
  event: MatSlideToggleChange
  row: RowData
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @Input() public columnsHeadersMap: Map<string, string>;
  @Input() public rowData: RowData[];
  @Input() public dataSource: MatTableDataSource<RowData> = new MatTableDataSource();
  @Input() public paginatorConfig?: PaginatorConfigOptions;
  @Input() public clickableRow?: boolean;
  @Input() public disabledSortColumns?: string[];
  @Input() public disableActiveInput: boolean = false;
  @Output() public onRowClick: EventEmitter<RowData> = new EventEmitter<RowData>();
  @Output() public onPageClick: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() public onSortClick: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() public onToggleClick: EventEmitter<ToggleEvent> = new EventEmitter<ToggleEvent>();
  @Output() public onDeleteButtonClick: EventEmitter<RowData> = new EventEmitter<RowData>();
  @Output() public onDownloadButtonClick: EventEmitter<RowData> = new EventEmitter<RowData>();
  @Output() public onStartButtonClick: EventEmitter<RowData> = new EventEmitter<RowData>();
  @Output() public onStopButtonClick: EventEmitter<RowData> = new EventEmitter<RowData>();
  @Output() public onCheckBoxClick: EventEmitter<RowData[]> = new EventEmitter<RowData[]>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public selection: SelectionModel<RowData>
  public initialSelection = [];
  public allowMultiSelect = true;

  constructor() {
    this.selection = new SelectionModel<RowData>(this.allowMultiSelect, this.initialSelection);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  public toggleAllRows(): void {
    this.isAllSelected() 
      ? this.selection.clear() 
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public ngAfterViewInit(): void {
    if (!!this.paginatorConfig) {
      this.paginator.pageIndex = this.paginatorConfig.pageIndex;
      this.paginator.pageSize = this.paginatorConfig.pageSize;
      this.paginator.length = this.paginatorConfig.length;
    }
  }

  public ngOnChanges(): void {
    this.dataSource.data = this.rowData
  }

  public getColumnNames(): string[] {
    const columnHeaders: string[] = Array.from(this.columnsHeadersMap.keys());
    const hasActiveColumn = this.rowData.some((rowData) => rowData.hasOwnProperty('active'));
    const hasSelectionColumn = this.rowData.some((rowData) => rowData.hasOwnProperty('select'));
    const hasActionsColumn = this.rowData.some((rowData) => rowData.hasOwnProperty('actions'));

    if (hasActiveColumn) {
      columnHeaders.push('active');
    }

    if (hasActionsColumn) {
      columnHeaders.push('actions');
    }

    if (hasSelectionColumn) {
      columnHeaders.push('select');
    }

    return columnHeaders;
  }
  
  public handleRowClick(event: MouseEvent, row: RowData): void {
    const target = event.target as HTMLElement;
    const classMatches = target.matches(
      '.mat-mdc-slide-toggle, .mdc-switch__icon, .mdc-switch, .mdc-switch__track, .mat-mdc-slide-toggle-checked, .mdc-switch__ripple, .mdc-switch__icon--off'
    );

    if (classMatches) {
      event.stopPropagation();
    } else {
      if (this.clickableRow) {
        this.onRowClick.emit(row);
      }
    }
  }

  public handleOnSortClick(sort: Sort): void {
    if (this.onSortClick) {
      this.onSortClick.emit(sort);
    }
  }

  public handleToggleClick(event: MatSlideToggleChange, row: RowData): void {
    if (this.onToggleClick) {
      this.onToggleClick.emit({ event, row });
    }
  }

  public handleOnPageClick(data: PageEvent): void {
    if (this.onPageClick) {
      this.onPageClick.emit(data);
    }
  }

  public isSortingDisabled(column: string): string | void {
    if (this?.disabledSortColumns?.length) {
      if (this.disabledSortColumns.includes(column)) {
        return column;
      }
    }
  }

  public handleDeleteButtonClick(event: MouseEvent, row: RowData): void {
    event.stopImmediatePropagation();
    this.onDeleteButtonClick.emit(row);
  }

  public handleDownloadButtonClick(event: MouseEvent, row: RowData): void {
    event.stopImmediatePropagation();
    this.onDownloadButtonClick.emit(row);
  }

  public handlePlayButtonClick(event: MouseEvent, row: RowData): void {
    event.stopImmediatePropagation();
    this.onStartButtonClick.emit(row);
  }

  public handleStopButtonClick(event: MouseEvent, row: RowData): void {
    event.stopImmediatePropagation();
    this.onStopButtonClick.emit(row);
  }

  public handleAllCheckBoxesClick(event: MatCheckboxChange): void {
    if (event) {
      this.toggleAllRows();
      this.onCheckBoxClick.emit(this.selection.selected);
    }
  }

  public handleCheckBoxClick(event: MatCheckboxChange, rows: RowData[]): void {
    if (event) {
      this.selection.toggle(rows)
      this.onCheckBoxClick.emit(this.selection.selected);
    }
  }
}
