<mat-form-field
  hideRequiredMarker
  #matFormFieldElement
  appearance="outline"
  class="w-100"
  [ngClass]="{
    'mat-form-field-invalid': control.touched && control.invalid
  }"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="number"
    [min]="minValue"
    [max]="maxValue"
    [value]=value
    (input)="handleInput($event)"
    (change)="handleChange()"
    (blur)="handleBlur()"
  />
  <span [ngClass]="{
    'suffix': true,
    'd-none': !value
  }">
    h
  </span>
  <mat-hint *ngIf="control.touched && control.invalid" class="pb-2 text-danger fw-bold">
    {{ getErrorMessage() }}
  </mat-hint>
</mat-form-field>