<ng-template #activeAndDesactiveModalContentTemplate>
  <span>Are you sure you want to do this action?</span>
</ng-template>

<ng-template #activeAndDesactiveModalActionsTemplate let-actionData>
  <div class="d-flex align-items-center gap-5 justify-content-end">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick(actionData)"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleModalToggleActionConfirmationButtonClick(actionData)"
    >
      Confirm
    </button>
  </div>
</ng-template>