import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, tap } from "rxjs";
import { format } from "date-fns";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";

import { CoreService } from "../../../../core/services/core.service";
import {
  PaginatorConfigOptions,
  RowData,
  SortConfigOptions,
  TableComponent
} from "../../../../shared/components/table/table.component";
import {
  SubmissionsOrderBy,
  SubmissionsSortColumns
} from "../../../../core/types/submission.types";
import { ModalComponent } from "../../../../shared/components/modal/modal.component";
import { DigitalLeadsService } from "../../../../core/services/digital-leads.service";
import { DigitalLead, DigitalLeadsListPageParams } from "../../../../core/types/digital-leads.types";
import { Roles } from "../../../../core/types/role.type";
import { AuthenticatedUser } from "../../../../core/types/auth.types";
import { AuthService } from "../../../../core/services/auth.service";

interface TableRowData {
  _id: string;
  name: string;
  requestedAmount: string;
  createdAt: string;
  cellPhone: string;
  email: string;
  supplier: string;
  select?: boolean;
}

@Component({
  selector: 'app-digital-leads-list',
  templateUrl: './digital-leads-list.component.html',
  styleUrls: ['./digital-leads-list.component.scss']
})
export class DigitalLeadsListComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild(TableComponent, { static: false }) tableComponent?: TableComponent;
  @ViewChild('fileUploadModalActionsTemplate')
  private fileUploadModalActionsTemplate: TemplateRef<any>;
  @ViewChild('sendToCampaignModalActionsTemplate')
  private sendToCampaignModalActionsTemplate: TemplateRef<any>;  
  @Input() public tableHeader: string;
  @ViewChild('input') private input: ElementRef;
  public isLoading: Observable<boolean>;
  public paginatorConfig?: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: SubmissionsOrderBy.Desc,
    orderByColumn: SubmissionsSortColumns.Id
  };
  public digitalLeads: DigitalLead[] = [];
  public digitalLeadsSelected: DigitalLead[] = [];
  public modalRef?: MatDialogRef<ModalComponent>;
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['supplier', 'Supplier'],
    ['name', 'Name'],
    ['email', 'Email'],
    ['requestedAmount', 'Amount'],
    ['createdAt', 'Date Created'],
    ['cellPhone', 'Mobile Phone'],
  ]);
  public tableRowData: TableRowData[] = [];
  public disabledSortColumns: string[] = [];
  public sendToCampaignForm: FormGroup;
  public roles: typeof Roles = Roles;
  public loggedUser: AuthenticatedUser;

  constructor(
    private coreService: CoreService,
    private cdr: ChangeDetectorRef,
    private digitalLeadsService: DigitalLeadsService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public get maxExportDate(): Date {
    const maxExportDate = new Date();
    return maxExportDate;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();
  }

  public ngOnChanges(): void {
    setTimeout(() => {
      this.getDigitalLeads({
        orderBy: this.sortConfig.orderBy,
      });
    });
  }

  public ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
          debounceTime(250),
          distinctUntilChanged(),
          tap(() => {
            this.getDigitalLeads({
              pageSize: 0,
              orderByColumn: this.sortConfig.orderByColumn,
              orderBy: this.sortConfig.orderBy,
              search: this.input.nativeElement.value,
            });
          })
      )
      .subscribe();
  }

  public handleTableRowClick(row: RowData): void {
    const navigateTo = [row['_id']];
    this.router.navigate(navigateTo, { relativeTo: this.route });
  }

  public handleCheckBoxRowClick(rows: RowData[]): void {
    const leads = [] as DigitalLead[];
    const leadsIds = rows.map(row => row['_id']);

    this.digitalLeads.forEach(lead => {
      if (leadsIds.includes(lead._id)) {
        leads.push(lead);
      }
    })

    this.digitalLeadsSelected = leads;
  }
  
  public handlePageClick(event: PageEvent): void {
    this.getDigitalLeads({
      page: event.pageIndex,
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy,
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getDigitalLeads({
      page: 0,
      pageSize: this?.paginatorConfig?.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction,
    });
  }

  public getDigitalLeads(params?: DigitalLeadsListPageParams): void {
    const search = params?.search?.replace(/,/g, '');

    this.coreService.setIsLoading(true);
    this.digitalLeadsService
      .getAllDigitalLeads({
        pageSize: params?.pageSize,
        page: params?.page,
        search: search,
        orderByColumn: params?.orderByColumn,
        orderBy: params?.orderBy,
      }).pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((getSbmissionsByStatusResponse) => {
        const { digitalLeads, ...pageData } = getSbmissionsByStatusResponse;
        this.digitalLeads = digitalLeads;
        if (!!this.paginatorConfig) {
          this.paginatorConfig.length = pageData.totalItems;
          this.paginatorConfig.pageIndex = pageData.page;
          this.paginatorConfig.pageSize = pageData.itemsPerPage;
          this.sortConfig.orderBy = pageData.orderBy;
          this.sortConfig.orderByColumn = pageData.orderByColumn;
        }
        this.prepareTableData();
        this.cdr.detectChanges();
        if (this.tableComponent) {
          this.tableComponent.selection.clear();
        }
        this.dialog.closeAll();
      })
  }

  private prepareTableData(): void {
    this.tableRowData = this.digitalLeads.map((digitalLead) => {
      const createdAtDate = new Date(digitalLead.createdAt);
      const formattedDate = format(new Date(createdAtDate), 'MM/dd/yyyy');
      const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
      const rowData: TableRowData = {
        _id: digitalLead._id,
        supplier: digitalLead.digitalLeadsSupplier.name,
        createdAt: formattedDate,
        email: digitalLead.email,
        name: `${digitalLead.firstName} ${digitalLead.lastName}`,
        cellPhone: digitalLead.cellPhone,
        requestedAmount: digitalLead.loanAmount ? currencyFormatter.format(digitalLead.loanAmount) : 'N/A',
      }

      if (this.loggedUser.attributes["custom:role"] === this.roles.SuperAdmin) {
        rowData["select"] = true;
      }

      return rowData;
    });
  }

  public handleImportLeadsAction(): void {
    this.getDigitalLeads({
      orderBy: this.sortConfig.orderBy,
    });
  }

  public handleSendLeadsToCampaignAction(): void {
    this.digitalLeadsSelected = [];
    this.getDigitalLeads({
      orderBy: this.sortConfig.orderBy,
    });
  }

  public handleImportButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        actionsTemplate: this.fileUploadModalActionsTemplate,
      },
      restoreFocus: false
    });
  }

  public handleSendToCampaignButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        actionsTemplate: this.sendToCampaignModalActionsTemplate,
      },
      restoreFocus: false
    });
  }
}
