import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";

interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[] = [];

  constructor(public router: Router, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.setupBreadcrumbs(this.route.root, '');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = [];

        this.setupBreadcrumbs(this.route.root, '');
      });
  }

  private setupBreadcrumbs(route: ActivatedRoute, url: string): void {
    const currentRouteChildren = route.children;

    if (currentRouteChildren.length) {
      currentRouteChildren.forEach((child) => {
        const childUrl = child.snapshot.url.map((segment) => segment.path).join('/');
        const label = child.snapshot.data['breadcrumb'];
        
        if (childUrl) {
          url += `/${childUrl}`;
        }

        if (label && !this.breadcrumbs.find((breadcrumb) => breadcrumb.url === url)) {
          this.breadcrumbs.push({ label, url })
        }

        this.setupBreadcrumbs(child, url);
      });
    }
  }
}
