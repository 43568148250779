import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html'
})
export class SubmissionsComponent implements OnInit {
  public campaign: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.campaign = params['id'];
    });    
  }
}
