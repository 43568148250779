<app-card class="p-4 affiliates-list">
  <div class="d-flex align-items-center justify-content-between">
    <h2>Directmail Imports</h2>
    <div class="d-flex align-items-center justify-content-center gap-3">
      <button
        mat-raised-button
        color="primary"
        (click)="handleButtonClick()"
      >
        Import
      </button>
    </div>
  </div>
  <mat-form-field> 
    <input matInput placeholder="Search Directmail Imports" #input>
  </mat-form-field>
  <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">
    No data received
  </h3>
  <app-table
    *ngIf="tableRowData.length"
    [columnsHeadersMap]="tableColumnsAndHeaders"
    [rowData]="tableRowData"
    [paginatorConfig]="paginatorConfig"
    (onPageClick)="handlePageClick($event)"
    (onSortClick)="handleSortClick($event)"
    (onDeleteButtonClick)="handleDeleteButtonClick($event)"
  ></app-table>
</app-card>

<ng-container>
  <ng-template #fileUploadModalActionsTemplate>
    <app-upload-purls 
      (onAction)="handleImportAction($event)" 
      [modalRef]="modalRef">
    </app-upload-purls>
  </ng-template>
</ng-container>

<app-delete-directmails-import-modal
  (cancel)="closeModal()"
  (confirm)="handleDeleteDirectmailModalConfirmButtonClick($event)"
></app-delete-directmails-import-modal>