<div>
  <h1 class="fw-bold">Export Digital Leads</h1>
</div>
<form [formGroup]="sendToCampaignForm">
  <div class="col-sm-6 w-100">
    <mat-form-field class="w-100" hideRequiredMarker appearance="outline">
      <mat-label>Campaigns</mat-label>
      <mat-select formControlName="campaign">
        <mat-option *ngFor="let option of campaignInputOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{ getErrorMessage('campaign', 'Campaign') }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex align-items-center justify-content-end gap-3 py-3">
    <button mat-button color="primary" (click)="handleModalClose()">
      Cancel
    </button>
    <button type="button" mat-raised-button color="primary" [disabled]="sendToCampaignForm.invalid"
      (click)="handleConfirmationButtonClick()">
      Confirm
    </button>
  </div>
</form>
