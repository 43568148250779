import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, lastValueFrom } from 'rxjs';

import { CoreService } from '../../../../../core/services/core.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../../../shared/components/modal/modal.component';
import { ToastrService } from 'ngx-toastr';
import { DocumentToBeRendered, FileEvent } from '../../../../../shared/components/upload-documents/upload-documents.component';
import { CreateUploadedDocument } from '../../../../../core/types/directmail.types';
import { DigitalLeadsService } from '../../../../../core/services/digital-leads.service';
import { DigitalLeadsSuppliersService } from '../../../../../core/services/digital-leads-suppliers.service';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-import-leads',
  templateUrl: './import-leads.component.html',
  styleUrls: ['./import-leads.component.scss']
})
export class ImportLeadsComponent implements OnInit {
  @Input() public modalRef?: MatDialogRef<ModalComponent>;
  @Output() public onAction: EventEmitter<void> = new EventEmitter<void>();
  public supplierInputOptions: SelectOption[] = [];
  public disabledDropZone: boolean;
  public importDigitalLeadsForm: FormGroup;
  public maxFileSize = 6000000;

  public documentsToBeUploaded: DocumentToBeRendered[] = [
    {
      type: 'digital_leads_file',
      label: 'Digital Leads',
      description: 'Upload CSV file (up to 6 MB in size).',
      uploadedFiles: []
    },
  ];

  constructor(
    private coreService: CoreService, 
    private digitalLeadsService: DigitalLeadsService,
    private digitalLeadsSuppliersService: DigitalLeadsSuppliersService,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.importDigitalLeadsForm = this.fb.nonNullable.group({
      supplier: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this.importDigitalLeadsForm.reset();    
    setTimeout(() => {
      this.getAllDigitalLeadsSuppliers();
    })
  }

  public get disableContinueButton(): boolean {
    const allDocumentsHaveAtLeastOneUploadedFile = this.documentsToBeUploaded
      .every((document) => document.uploadedFiles.length);

      if (!allDocumentsHaveAtLeastOneUploadedFile || !this.importDigitalLeadsForm.valid) {
        return true;
      }
      return false;
  }

  public handleFileAction(fileEvent: FileEvent): void {
    if (fileEvent.error) {
      this.toastrService.error(fileEvent.error, undefined, { positionClass: 'toast-custom-bottom-center' });
      return;
    }

    const correspondingDocument = this.documentsToBeUploaded.find(
      (documentToBeUploaded) => documentToBeUploaded.type === fileEvent.documentKey
    )!;

    if (fileEvent.action === 'upload') {
      fileEvent.uploadedFiles.forEach(file => {
        correspondingDocument.uploadedFiles.push({
          ...file,
          displayTrashButton: true
        })
      })
    } else {
      this.handleDeleteFile(correspondingDocument, fileEvent);
    }
  }

  public async handleContinueButtonClick(): Promise<void> {
    this.importDigitalLeadsForm.markAllAsTouched();

    if (this.importDigitalLeadsForm.valid) {
      try {
        this.coreService.setIsLoading(true);
  
        const createUploadedDocuments: CreateUploadedDocument[] = [];
        const importFormPayload = this.importDigitalLeadsForm.value;
    
        this.documentsToBeUploaded.forEach((document) => {
          document.uploadedFiles = document.uploadedFiles.filter((uploadedFile) => uploadedFile.file.size);
    
          if (document.uploadedFiles.length) {
            createUploadedDocuments.push({
              documentType: document.type,
              files: document.uploadedFiles.map((uploadedFile) => uploadedFile.file)
            })
          }
        });
  
        if (createUploadedDocuments.length) {
          await lastValueFrom(
           this.digitalLeadsService.batchDigitalLeadsFile(importFormPayload.supplier, createUploadedDocuments)
          );

          this.onAction.emit();
  
          const successMessage = `Digital leads file was imported successfully`
          this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
        }
      } catch(error) {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public handleModalClose(): void {
    this.modalRef!.close();
    this.importDigitalLeadsForm.reset();
  }

  public getErrorMessage(controlName: keyof typeof this.importDigitalLeadsForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.importDigitalLeadsForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }


  private async handleDeleteFile(correspondingDocument: DocumentToBeRendered, fileEvent: FileEvent): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const fileToBeDeletedIdx = correspondingDocument.uploadedFiles.findIndex((uploadedFile) => uploadedFile.id === fileEvent.uploadedFiles[0].id);
  
      correspondingDocument.uploadedFiles.splice(fileToBeDeletedIdx, 1);
    } finally {
      this.coreService.setIsLoading(false);
    }
  }

  private getAllDigitalLeadsSuppliers(): void {
    this.coreService.setIsLoading(true);
    this.digitalLeadsSuppliersService.getAllDigitalLeadsSuppliers().pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllSuppliersResponse) => {
      const digitalLeadsSuppliers = getAllSuppliersResponse;

      digitalLeadsSuppliers.forEach(supplier => {
        this.supplierInputOptions.push({ label: supplier.name, value: supplier._id })
      });
    })
  }
}
