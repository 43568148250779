import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

import { DigitalLeadDetailsComponent } from "./digital-lead-details.component";
import { DigitalLeadConsumerInformationTabComponent } from "./digital-lead-consumer-information-tab/digital-lead-consumer-information-tab.component";
import { CardModule } from "../../../../shared/components/card/card.module";

@NgModule({
  declarations: [DigitalLeadDetailsComponent, DigitalLeadConsumerInformationTabComponent],
  exports: [DigitalLeadDetailsComponent],
  imports: [CommonModule, NgxMaskDirective, NgxMaskPipe, CardModule],
  providers: [provideNgxMask()]
})
export class DigitalLeadDetailsModule {}
