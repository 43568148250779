<mat-sidenav-container class="w-100">
  <mat-sidenav
    #sidenav
    [opened]="true"
    class="sidenav-bg"
    [mode]="isMobile ? 'over' : 'side'"
  >
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container">
      <div class="page">
        <div class="pt-4 px-2 d-flex align-items-center justify-content-between">
          <button 
            class="align-self-start"
            mat-button 
            (click)="sidenav.toggle()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <app-logged-user *ngIf="loggedUser"></app-logged-user>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="ps-3 display-4">{{ pageLabel }}</h1>
        </div>
        <app-breadcrumb></app-breadcrumb>
        <div class="pb-4">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
