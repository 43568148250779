import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, lastValueFrom } from 'rxjs';

import { CoreService } from '../../../../core/services/core.service';
import { DirectmailService } from '../../../../core/services/directmail.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { LandingPageService } from '../../../../core/services/landing-page.service';
import { ToastrService } from 'ngx-toastr';
import { ImportEvent } from '../directmail-imports.component';
import { DocumentToBeRendered, FileEvent } from '../../../../shared/components/upload-documents/upload-documents.component';
import { CreateUploadedDocument } from '../../../../core/types/directmail.types';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-upload-purls',
  templateUrl: './upload-purls.component.html',
  styleUrls: ['./upload-purls.component.scss']
})
export class UploadPurlsComponent implements OnInit {
  @Input() public modalRef?: MatDialogRef<ModalComponent>;
  @Output() public onAction: EventEmitter<ImportEvent> = new EventEmitter<ImportEvent>();
  public campaignInputOptions: SelectOption[] = [];
  public disabledDropZone: boolean;
  public importForm: FormGroup;
  public maxFileSize = 6000000;

  public documentsToBeUploaded: DocumentToBeRendered[] = [
    {
      type: 'mail_file',
      label: 'Purl DMS',
      description: 'Upload DMS campaign CSV file (up to 6 MB in size).',
      uploadedFiles: []
    },
  ];

  constructor(
    private coreService: CoreService, 
    private directmailService: DirectmailService,
    private landingPageService: LandingPageService,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.importForm = this.fb.nonNullable.group({
      campaign: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getAllCampaigns();
    })
  }

  public get disableContinueButton(): boolean {
    const allDocumentsHaveAtLeastOneUploadedFile = this.documentsToBeUploaded
      .every((document) => document.uploadedFiles.length);

      if (!allDocumentsHaveAtLeastOneUploadedFile || !this.importForm.valid) {
        return true;
      }
      return false;
  }

  public handleFileAction(fileEvent: FileEvent): void {
    if (fileEvent.error) {
      this.toastrService.error(fileEvent.error, undefined, { positionClass: 'toast-custom-bottom-center' });
      return;
    }

    const correspondingDocument = this.documentsToBeUploaded.find(
      (documentToBeUploaded) => documentToBeUploaded.type === fileEvent.documentKey
    )!;

    if (fileEvent.action === 'upload') {
      fileEvent.uploadedFiles.forEach(file => {
        correspondingDocument.uploadedFiles.push({
          ...file,
          displayTrashButton: true
        })
      })
    } else {
      this.handleDeleteFile(correspondingDocument, fileEvent);
    }
  }

  public async handleContinueButtonClick(): Promise<void> {
    this.importForm.markAllAsTouched();

    if (this.importForm.valid) {
      try {
        this.coreService.setIsLoading(true);
  
        const createUploadedDocuments: CreateUploadedDocument[] = [];
        const importFormPayload = this.importForm.value;
    
        this.documentsToBeUploaded.forEach((document) => {
          document.uploadedFiles = document.uploadedFiles.filter((uploadedFile) => uploadedFile.file.size);
    
          if (document.uploadedFiles.length) {
            createUploadedDocuments.push({
              documentType: document.type,
              files: document.uploadedFiles.map((uploadedFile) => uploadedFile.file)
            })
          }
        });
  
        if (createUploadedDocuments.length) {
          const directmailImport = await lastValueFrom(
           this.directmailService.uploadPurlFile(importFormPayload.campaign!, createUploadedDocuments)
          );
  
          this.onAction.emit({ directmailImport });

          const successMessage = `Directmail file was imported successfully`
          this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
        }
      } finally {
        this.coreService.setIsLoading(false);
        this.modalRef!.close();
        this.importForm.reset();    
      }
    }
  }

  public handleModalClose(): void {
    this.modalRef!.close();
    this.importForm.reset();
  }

  public getErrorMessage(controlName: keyof typeof this.importForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.importForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }


  private async handleDeleteFile(correspondingDocument: DocumentToBeRendered, fileEvent: FileEvent): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const fileToBeDeletedIdx = correspondingDocument.uploadedFiles.findIndex((uploadedFile) => uploadedFile.id === fileEvent.uploadedFiles[0].id);
  
      correspondingDocument.uploadedFiles.splice(fileToBeDeletedIdx, 1);
    } finally {
      this.coreService.setIsLoading(false);
    }
  }

  private getAllCampaigns(): void {
    this.coreService.setIsLoading(true);
    this.landingPageService.getAllLandingPages().pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllCampaignsResponse) => {
      const landingPages = getAllCampaignsResponse;

      landingPages.forEach(landingPage => {
        this.campaignInputOptions.push({ label: landingPage.campaign, value: landingPage.campaign })
      });
    })
  }
}
