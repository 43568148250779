import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { Job, ToggleJobStateDto } from "../types/jobs.type";

@Injectable({ providedIn: 'root' })
export class JobsService {
  private apiUrl = `${environment.apiUrl}/jobs`;

  constructor(private http: HttpClient) {}

  public get(): Observable<Job[]> {
    return this.http.get<Job[]>(this.apiUrl, { withCredentials: true });
  }

  public toggleState(name: string, toggleJobStateDto: ToggleJobStateDto): Observable<Job> {
    return this.http.patch<Job>(
      `${this.apiUrl}/${name}/state`,
      toggleJobStateDto,
      { withCredentials: true }
    );
  }
}
