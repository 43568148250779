import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize, lastValueFrom } from 'rxjs';

import { CoreService } from '../../../../core/services/core.service';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { CreateDigitalLeadsSupplierDto, DigitalLeadsSupplier } from '../../../../core/types/digital-leads.types';
import { DigitalLeadsSuppliersService } from '../../../../core/services/digital-leads-suppliers.service';
import { AuthenticatedUser } from '../../../../core/types/auth.types';
import { AuthService } from '../../../../core/services/auth.service';
import { Roles } from '../../../../core/types/role.type';

@Component({
  selector: 'app-digital-leads-suppliers-creation-edition',
  templateUrl: './digital-leads-suppliers-creation-edition.component.html'
})
export class DigitalLeadsSuppliersCreationEditionComponent implements OnInit {
  @ViewChild('changeDigitalLeadsSupplierAPIKeyModalContentTemplate') private changeDigitalLeadsSupplierAPIKeyModalContentTemplate: TemplateRef<any>;
  @ViewChild('changeDigitalLeadsSupplierAPIKeyModalActionsTemplate') private changeDigitalLeadsSupplierAPIKeyModalActionsTemplate: TemplateRef<any>;
  public digitalLeadsSupplier: DigitalLeadsSupplier;
  public digitalLeadsSupplierId: string;
  public selected: string | undefined;
  public digitalLeadsSupplierForm: FormGroup;
  public modalRef?: MatDialogRef<ModalComponent>;
  public hideApiKeyValue = true;
  public isInputVisible: boolean = false;
  public roles: typeof Roles = Roles;
  public loggedUser: AuthenticatedUser;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private digitalLeadsSuppliersService: DigitalLeadsSuppliersService,
    private coreService: CoreService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) { 
    this.digitalLeadsSupplierForm = this.fb.group({
      name: ['', Validators.required],
      apiKey: [{ value: '', disabled: true }, Validators.required]
    });
  }

  public ngOnInit(): void {
    this.digitalLeadsSupplierId = this.route.snapshot.params['id'];
    this.loggedUser = this.authService.getLoggedUser();

    setTimeout(() => {
      if (!!this.digitalLeadsSupplierId) {
        this.getDigitalLeadsSupplier();
      }
    })
  }

  public getErrorMessage(controlName: keyof typeof this.digitalLeadsSupplierForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.digitalLeadsSupplierForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  private getDigitalLeadsSupplier(): void {
    this.coreService.setIsLoading(true);
    this.digitalLeadsSuppliersService
      .getDigitalLeadsSupplier(this.digitalLeadsSupplierId)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        next: (digitalLeadsSupplier) => {
          this.digitalLeadsSupplier = digitalLeadsSupplier;
        },
        complete: () => {
          this.setupFormInitialState();
        }
      });
  }

  public handleBackButtonClick(): void {
    const routePath = this.digitalLeadsSupplierId ? '../../../digital-leads-suppliers' : '../../digital-leads-suppliers';
    this.router.navigate(
      [routePath],
      { relativeTo: this.route }
    );
  }

  public async handleContinueButtonClick(): Promise<void> {
    this.digitalLeadsSupplierForm.markAllAsTouched();

    if (this.digitalLeadsSupplierForm.valid) {
      try {
        this.coreService.setIsLoading(true);
        const digitalLeadsSupplierDto = this.digitalLeadsSupplierForm.value as CreateDigitalLeadsSupplierDto;
  
        if (!!this.digitalLeadsSupplierId) {
          await lastValueFrom(this.digitalLeadsSuppliersService.editDigitalLeadsSupplier(this.digitalLeadsSupplierId, digitalLeadsSupplierDto))
        } else {
          await lastValueFrom(this.digitalLeadsSuppliersService.createDigitalLeadsSupplier(digitalLeadsSupplierDto))
        }
  
        const routePath = this.digitalLeadsSupplierId ? '../../../digital-leads-suppliers' : '../../digital-leads-suppliers';
  
        this.router.navigate(
          [routePath],
          { relativeTo: this.route }
        );
        const successMessage = `Digital Leads Supplier was ${this.digitalLeadsSupplierId ? 'updated' : 'created'} successfully`
        this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
  
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public handleChangeAPIKeyButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Generate New Digital Leads Supplier API Key',
        contentTemplate: this.changeDigitalLeadsSupplierAPIKeyModalContentTemplate,
        actionsTemplate: this.changeDigitalLeadsSupplierAPIKeyModalActionsTemplate
      },
      restoreFocus: false
    });
  }

  public handleModalChangeAPIKeyConfirmationButtonClick(): void {
    this.coreService.setIsLoading(true);
    this.digitalLeadsSuppliersService
      .generateNewApiKey(this.digitalLeadsSupplierId)
      .subscribe(() => {
        const routePath = '../../../digital-leads-suppliers' ;
        this.modalRef!.close();
        this.toastrService.success(
          "New API Key has been successfully generated", 
          undefined, 
          { positionClass: 'toast-custom-bottom-center' }
        );
        this.router.navigate(
          [routePath],
          { relativeTo: this.route }
        );
      })
  }

  public toggleVisibility(): void {
    this.isInputVisible = !this.isInputVisible;
  }

  public getSecurityInputClass(): string {
    return this.isInputVisible ? 'input-security-none' : 'input-security-disc';
  }

  private setupFormInitialState(): void {
    this.digitalLeadsSupplierForm.patchValue({
      name: this.digitalLeadsSupplier.name,
      apiKey: this.digitalLeadsSupplier.apiKey        
    });

    if (this.loggedUser?.attributes["custom:role"] !== this.roles.SuperAdmin) {
      this.digitalLeadsSupplierForm.disable();
    }
  }
}
