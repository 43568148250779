<ng-container *ngFor="let document of documentsToBeRenderedMap | keyvalue: keepDocumentsToBeRenderedMapOrder">
  <mat-expansion-panel
    #expansionPanel="matExpansionPanel"
    [expanded]="document.value.uploadedFiles.length"
    class="expansion-panel"
  >
    <mat-expansion-panel-header
      [ngClass]="{ 'expanded-panel-header': expansionPanel.expanded }"
    >
      <span class="document-label">
        {{ document.value.label }}
      </span>
      <span class="document-description fw-bold">
        {{ document.value.description }}
      </span>
    </mat-expansion-panel-header>
    <ng-container>
      <ngx-file-drop
        [directory]="false"
        [multiple]="false"
        (onFileDrop)="handleFileDrop($event, document.key)"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <button 
            class="btn fs-6 drop-files-label" 
            [disabled]="disabledDropZone"
            (click)="openFileSelector()"
          >
            Drop files here to upload or choose file
          </button>
        </ng-template>
      </ngx-file-drop>
      <div
        *ngFor="let uploadedFile of document.value.uploadedFiles"
        class="uploaded-file"
      >
        <div class="d-flex gap-3">
          <div>
            <mat-icon svgIcon="file"></mat-icon>
          </div>
          <div class="flex-grow-1">
            <div class="
              fw-bold
              d-flex
              align-items-center
              justify-content-between
              gap-3
            ">
              <span>{{ uploadedFile.file.name }}</span>
              <div class="
                d-flex
                align-items-center
                gap-2
              ">
                <mat-icon
                  *ngIf="uploadedFile.displayDownloadButton"
                  inline
                  class="download-icon"
                  (click)="downloadFile(uploadedFile)"
                >
                  cloud_download
                </mat-icon>
                <mat-icon
                  *ngIf="uploadedFile.displayTrashButton"
                  svgIcon="trash"
                  class="trash-icon"
                  (click)="deleteFile(document.key, uploadedFile)"
                ></mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="uploadedFile.status"
          [ngClass]="{
            'document-status-container': true,
            'text-success': uploadedFile.status === 'approved',
            'text-danger': uploadedFile.status === 'rejected'
          }"
        >
          <span class="fw-bold">{{ uploadedFile.status | titlecase }}</span>
          <span *ngIf="uploadedFile.rejectReason">: {{ uploadedFile.rejectReason }}</span>
        </div>
      </div>
    </ng-container>
  </mat-expansion-panel>
</ng-container>