import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { finalize, Observable } from "rxjs";
import { format } from "date-fns";

import {
  PaginatorConfigOptions,
  RowData,
  SortConfigOptions,
  ToggleEvent
} from "../../../shared/components/table/table.component";
import { AuthService } from "../../../core/services/auth.service";
import { CoreService } from "../../../core/services/core.service";
import { AffiliateService } from "../../../core/services/affiliate.service";
import { AffiliateDigitalLeadSetting, affiliateDigitalLeadSettingDayOptions, AffiliateDigitalLeadSettingsParams } from "../../../core/types/affiliate.types";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import {
  ActivateDeactivateAffiliateDigitalLeadSettingModalComponent
} from "./activate-deactivate-affiliate-digital-lead-setting-modal/activate-deactivate-affiliate-digital-lead-setting-modal.component";
import { Roles } from "../../../core/types/role.type";
import { AuthenticatedUser } from "../../../core/types/auth.types";

export interface TableRowData {
  id: string;
  active: boolean;
  affiliate: string;
  campaign: string;
  days: string;
  fromHour: string;
  toHour: string
  amount: number;
  createdAt: string;
  states: string;
  timezone: string;
}

@Component({
  selector: 'app-affiliate-digital-lead-settings',
  templateUrl: './affiliate-digital-lead-settings.component.html',
  styleUrls: ['./affiliate-digital-lead-settings.component.scss']
})
export class AffiliateDigitalLeadSettingsComponent implements OnInit {
  @ViewChild(ActivateDeactivateAffiliateDigitalLeadSettingModalComponent)
  private activateDeactivateAffiliateDigitalLeadSettingModalComponent: ActivateDeactivateAffiliateDigitalLeadSettingModalComponent;
  public isLoading: Observable<boolean>;
  public paginatorConfig: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: 'DESC',
    orderByColumn: 'createdAt'
  };
  public affiliateDigitalLeadSettings: AffiliateDigitalLeadSetting[] = [];
  public tableRowData: TableRowData[] = [];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['affiliate', 'Affiliate'],
    ['campaign', 'Campaign'],
    ['days', 'Days'],
    ['fromHour', 'From'],
    ['toHour', 'To'],
    ['timezone', 'Timezone'],
    ['amount', 'Amount'],
    ['states', 'States'],
    ['createdAt', 'Date Created']
  ]);
  public modalRef?: MatDialogRef<ModalComponent>;
  public roles: typeof Roles = Roles;
  public loggedUser: AuthenticatedUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService,
    private coreService: CoreService,
    private affiliateService: AffiliateService
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public get disableActiveInput(): boolean {
    return this.loggedUser?.attributes["custom:role"] !== this.roles.SuperAdmin;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();

    setTimeout(() => {
      this.getAffiliateDigitalLeadSettings();
    });
  }

  public handleAddSettingButtonClick(): void {
    this.router.navigate(
      ['../affiliate-digital-lead-settings/creation'],
      { relativeTo: this.route }
    );
  }

  public handlePageClick(event: PageEvent): void {
    this.getAffiliateDigitalLeadSettings({
      page: event.pageIndex, 
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy
    });
  }

  public handleTableRowClick(row: RowData): void {
    this.router.navigate(
      ['../affiliate-digital-lead-settings/creation', row['id']],
      { relativeTo: this.route }
    );
  }

  public handleSortClick(sort: Sort): void {
    this.getAffiliateDigitalLeadSettings({
      page: 0,
      pageSize: this.paginatorConfig.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction
    });
  }

  public handleToggleClick({ event, row }: ToggleEvent): void {
    const settingRow = row as TableRowData;
    const setting = this.affiliateDigitalLeadSettings.find((setting) => setting._id === settingRow.id);

    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      autoFocus: 'dialog',
      data: {
        title: `${event.checked ? 'Activate' : 'Deactivate'} Setting`,
        contentTemplate:
          this.activateDeactivateAffiliateDigitalLeadSettingModalComponent.activateDeactivateAffiliateDigitalLeadSettingModalContentTemplate,
        actionsTemplate:
          this.activateDeactivateAffiliateDigitalLeadSettingModalComponent.activateDeactivateAffiliateDigitalLeadSettingModalActionsTemplate,
        context: { ...setting, active: event.checked }
      },
      restoreFocus: false
    });
  }

  public handleActivateDeactivateSettingModalCancelButtonClick(setting: AffiliateDigitalLeadSetting): void {
    const settingRowIndex = this.tableRowData.findIndex(rowSetting => rowSetting.id === setting._id);

    this.tableRowData[settingRowIndex].active = !setting.active;

    this.closeModal();
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private getAffiliateDigitalLeadSettings(params?: AffiliateDigitalLeadSettingsParams): void {
    this.coreService.setIsLoading(true);
    this.affiliateService.getAffiliateDigitalLeadSettings({
      page: this.paginatorConfig.pageIndex!,
      pageSize: this.paginatorConfig.pageSize!,
      orderByColumn: params?.orderByColumn || 'createdAt',
      orderBy: params?.orderBy || 'DESC',
    })
    .pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    )
    .subscribe({
      next: (affiliateDigitalLeadSettingsResponse) => {
        const { affiliateDigitalLeadSettings, ...params } = affiliateDigitalLeadSettingsResponse;

        this.affiliateDigitalLeadSettings = affiliateDigitalLeadSettings;
        this.paginatorConfig.length = params.totalItems;
        this.paginatorConfig.pageIndex = params.page;
        this.paginatorConfig.pageSize = params.itemsPerPage;
        this.sortConfig.orderBy = params.orderBy;
        this.sortConfig.orderByColumn = params.orderByColumn;
      },
      complete: () => {
        this.prepareTableData();
      }
    });
  }

  private prepareTableData(): void {
    this.tableRowData = this.affiliateDigitalLeadSettings.map((setting) => ({
      id: setting._id,
      affiliate: setting.affiliate.name,
      campaign: setting.campaign,
      days: affiliateDigitalLeadSettingDayOptions
        .filter((dayOption) => setting.daysOfWeek.includes(Number(dayOption.value)))
        .map((dayOption) => dayOption.label)
        .join(', '),
      fromHour: `${setting.fromHour}h`,
      toHour: `${setting.toHour}h`,
      timezone: setting.timezone,
      amount: setting.amount,
      states: setting.states?.join(', ') || 'N/A',
      createdAt: format(new Date(setting.createdAt), 'MM/dd/yyyy'),
      active: setting.active
    }));
  }
}
