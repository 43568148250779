<ng-template #importUsersModalContentTemplate>
  <mat-form-field
    hideRequiredMarker
    class="w-100 mb-3"
    appearance="outline"
  >
    <mat-label>Affiliate</mat-label>
    <mat-select [formControl]="affiliate">
      <mat-option *ngFor="let option of affiliateInputOptions" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error>
      Affiliate is required
    </mat-error>
  </mat-form-field>
  <app-upload-documents
    [documentsToBeRendered]="documentsToBeUploaded"
    [maxFileSize]="6000000"
    [allowedFileTypes]="['.csv']"
    [disabledDropZone]="documentsToBeUploaded[0].uploadedFiles.length > 0"
    (onFileUpload)="handleFileAction($event)"
    (onFileDelete)="handleFileAction($event)"
  ></app-upload-documents>
</ng-template>

<ng-template #importUsersModalActionsTemplate>
  <div class="
    d-flex
    align-items-center
    gap-3
    justify-content-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="disableContinueButton"
      (click)="handleConfirmButtonClick()"
    >
      Confirm
    </button>
  </div>
</ng-template>