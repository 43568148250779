import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, finalize, forkJoin, last, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { CoreService } from "../../../core/services/core.service";
import { RowData, ToggleEvent } from "../../../shared/components/table/table.component";
import { UsersService } from "../../../core/services/users.service";
import { Roles } from "../../../core/types/role.type";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { ImportUsersModalComponent } from "./import-users-modal/import-users-modal.component";
import { ActivateDeactivateUserModalComponent } from "./activate-deactivate-user-modal/activate-deactivate-user-modal.component";
import { DeleteUserModalComponent } from "./delete-user-modal/delete-user-modal.component";
import { Affiliate } from "../../../core/types/affiliate.types";
import { AffiliateService } from "../../../core/services/affiliate.service";
import { User } from "../../../core/types/user.types";
import { AuthenticatedUser } from "../../../core/types/auth.types";
import { AuthService } from "../../../core/services/auth.service";

interface TableRowData {
  id: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild(ImportUsersModalComponent) private importUsersModalComponent: ImportUsersModalComponent;
  @ViewChild(ActivateDeactivateUserModalComponent)
  private activateDeactivateUserModalComponent: ActivateDeactivateUserModalComponent;
  @ViewChild(DeleteUserModalComponent) private deleteUserModalComponent: DeleteUserModalComponent;
  public isLoading: Observable<boolean>;
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['name', 'Name'],
    ['email', 'Email'],
    ['role', 'Role']
  ]);
  public tableRowData: TableRowData[] = [];
  public modalRef?: MatDialogRef<ModalComponent>;
  public affiliates: Affiliate[] = [];
  private users: User[] = [];
  public loggedUser: AuthenticatedUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private coreService: CoreService,
    private usersService: UsersService,
    private affiliatesService: AffiliateService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();

    setTimeout(() => {
      this.setupInitialData();
    });
  }

  public handleAddButtonClick(): void {
    this.router.navigate(
      ["../users/creation"],
      { relativeTo: this.route }
    );
  }

  public handleImportButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      autoFocus: 'dialog',
      data: {
        title: 'Import Users',
        contentTemplate: this.importUsersModalComponent.importUsersModalContentTemplate,
        actionsTemplate: this.importUsersModalComponent.importUsersModalActionsTemplate
      },
      restoreFocus: false
    });
  }

  public handleImportUsersModalConfirm(): void {
    this.closeModal();
    this.coreService.setIsLoading(true);

    setTimeout(() => {
      this
      .getUsers()
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        complete: () => {
          this.prepareTableData();
        },
        error: (error) => {
          this.toastrService.error(
            error,
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
    }, 2000);
  }

  public handleTableRowClick(row: RowData): void {
    this.router.navigate(
      ['../users/creation', row['id']],
      { relativeTo: this.route }
    );
  }

  public handleToggleClick({ event, row }: ToggleEvent): void {
    const user = row as TableRowData;

    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        title: 'Active/Deactivate User',
        contentTemplate: this.activateDeactivateUserModalComponent.activeAndDesactiveModalContentTemplate,
        actionsTemplate: this.activateDeactivateUserModalComponent.activeAndDesactiveModalActionsTemplate,
        context: { userId: user.id, action: event.checked }
      },
      restoreFocus: false
    });
  }

  public handleDeleteButtonClick(row: RowData): void {
    const userTableRowData = row as TableRowData;

    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Delete User',
        contentTemplate: this.deleteUserModalComponent.deleteUserModalContentTemplate,
        actionsTemplate: this.deleteUserModalComponent.deleteUserModalActionsTemplate,
        context: { userId: userTableRowData.id }
      },
      restoreFocus: false
    });
  }

  public handleActivateDeactivateUserModalCancelButtonClick(
    { userId, action }: { userId: string, action: boolean }
  ): void {
    const userIndex = this.tableRowData.findIndex(customer => customer.id === userId);

    this.tableRowData[userIndex].active = !action;

    this.closeModal();
  }

  public handleDeleteUserModalConfirmButtonClick(userId: string): void {
    this.users = this.users.filter((user) => user.id !== userId);

    this.prepareTableData();
    this.closeModal();
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  public handleModalDeleteConfirmationButtonClick(userId: string): void {
    this.coreService.setIsLoading(true);
    this.usersService
      .deleteUser(userId)
      .pipe(
        finalize(() => {
          this.modalRef!.close();
          this.modalRef = undefined;
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        next: () => {
          this.users = this.users.filter((user) => user.id !== userId);

          this.prepareTableData();
          this.toastrService.success(
            "User has been successfully deleted", 
            undefined, 
            { positionClass: 'toast-custom-bottom-center' }
          );
        },
        error: (error) => {
          this.toastrService.error(
            error,
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
  }

  private setupInitialData(): void {
    this.coreService.setIsLoading(true);
    forkJoin([this.getUsers(), this.getAffiliates()])
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        complete: () => {
          this.prepareTableData();
        },
        error: (error) => {
          this.toastrService.error(
            error,
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
  }

  private getUsers(): Observable<User[]> {
    return this.usersService
      .getUsers()
      .pipe(
        last(),
        tap((users) => {
          this.users = users;
        })
      );
  }

  private getAffiliates(): Observable<Affiliate[]> {
    return this.affiliatesService
      .getAllAffiliates()
      .pipe(
        tap((affiliates) => {
          this.affiliates = affiliates;
        })
      );
  }

  private prepareTableData(): void {
    this.tableRowData = this.users.map((user) => ({
      id: user.id,
      name: user.name!,
      email: user.email,
      role: user.role,
      active: user.active,
      actions: {
        delete: this.loggedUser.attributes["custom:role"] === Roles.SuperAdmin
      }
    }));
  }
}
