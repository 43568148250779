import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";

import { CoreService } from "../../../../../core/services/core.service";
import { DigitalLead } from "../../../../../core/types/digital-leads.types";

@Component({
  selector: 'app-digital-lead-consumer-information-tab',
  templateUrl: './digital-lead-consumer-information-tab.component.html',
  styleUrls: ['./digital-lead-consumer-information-tab.component.scss']
})
export class DigitalLeadConsumerInformationTabComponent {
  @Input() public digitalLead?: DigitalLead;
  public isLoading: Observable<boolean>;

  constructor(private coreService: CoreService) {
    this.isLoading = this.coreService.isLoading$;
  }
}
