import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, forkJoin, Observable, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "../../../../core/services/auth.service";
import { CoreService } from "../../../../core/services/core.service";
import { LandingPage } from "../../../../core/types/landing-page.types";
import { LandingPageService } from "../../../../core/services/landing-page.service";
import { AffiliateService } from "../../../../core/services/affiliate.service";
import {
  AffiliateDigitalLeadSetting,
  affiliateDigitalLeadSettingDayOptions,
  AffiliateDigitalLeadSettingsResponse
} from "../../../../core/types/affiliate.types";
import { Roles } from "../../../../core/types/role.type";
import { AuthenticatedUser } from "../../../../core/types/auth.types";

interface SelectOption {
  label: string;
  value: string;
}

interface AffiliateDigitalLeadSettingFormValue {
  amount: number;
  campaign: string;
  daysOfWeek: number[];
  fromHour: number;
  toHour: number;
  states: string[];
  timezone: string;
}

@Component({
  selector: 'app-affiliate-digital-lead-settings-creation-edition',
  templateUrl: './affiliate-digital-lead-settings-creation-edition.component.html'
})
export class AffiliateDigitalLeadSettingsCreationEditionComponent implements OnInit {
  public affiliateDigitalLeadSettingForm: FormGroup;
  public campaignInputOptions: SelectOption[] = [];
  public daysOfWeekInputOptions: SelectOption[] = affiliateDigitalLeadSettingDayOptions;
  public timezoneInputOptions: SelectOption[] = [
    { label: 'America/New_York', value: 'America/New_York' },
    { label: 'America/Chicago', value: 'America/Chicago' },
    { label: 'America/Denver', value: 'America/Denver' },
    { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
    { label: 'America/Phoenix', value: 'America/Phoenix' },
    { label: 'America/Anchorage', value: 'America/Anchorage' },
    { label: 'America/Adak', value: 'America/Adak' },
    { label: 'America/Honolulu', value: 'America/Honolulu' },
    { label: 'America/Toronto', value: 'America/Toronto' },
    { label: 'America/Vancouver', value: 'America/Vancouver' },
    { label: 'America/Mexico_City', value: 'America/Mexico_City' },
    { label: 'America/Bogota', value: 'America/Bogota' },
    { label: 'America/Sao_Paulo', value: 'America/Sao_Paulo' },
    { label: 'America/Caracas', value: 'America/Caracas' },
    { label: 'America/Lima', value: 'America/Lima' },
    { label: 'America/Argentina/Buenos_Aires', value: 'America/Argentina/Buenos_Aires' },
    { label: 'America/Santiago', value: 'America/Santiago' },
    { label: 'America/Montevideo', value: 'America/Montevideo' },
    { label: 'America/Puerto_Rico', value: 'America/Puerto_Rico' },
  ];
  public statesInputOptions: SelectOption[] = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
  ];
  public affiliateDigitalLeadSettingId: string;
  private landingPages: LandingPage[] = [];
  private affiliateDigitalLeadSettings: AffiliateDigitalLeadSetting[] = [];
  public roles: typeof Roles = Roles;
  public loggedUser: AuthenticatedUser;

  public constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private coreService: CoreService,
    private landingPageService: LandingPageService,
    private affiliatesService: AffiliateService
  ) {
    this.affiliateDigitalLeadSettingForm = this.fb.nonNullable.group({
      campaign: ['', Validators.required],
      daysOfWeek: [[], Validators.required],
      fromHour: ['', [Validators.required, Validators.min(0), Validators.max(22)]],
      toHour: ['', [Validators.required, Validators.min(1), Validators.max(23)]],
      timezone: ['', Validators.required],
      amount: ['', Validators.required],
      states: [[]]
    });
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();

    setTimeout(() => {
      this.setupInitialData(); 
    });
  }

  public handleAffiliateDigitalLeadSettingFormSubmit(): void {
    if (this.affiliateDigitalLeadSettingForm.valid) {
      const affiliateDigitalLeadSettingFormValue = this.affiliateDigitalLeadSettingForm.value as AffiliateDigitalLeadSettingFormValue;
      const parsedDaysOfWeek = affiliateDigitalLeadSettingFormValue.daysOfWeek.map((day) => Number(day));
      const affiliateId = this.landingPages
        .find((landingPage) => landingPage.campaign === affiliateDigitalLeadSettingFormValue.campaign)!.affiliate as string;

      this.coreService.setIsLoading(true);
      this.affiliatesService
        .putAffiliateDigitalLeadSettings(
          affiliateId,
          { ... affiliateDigitalLeadSettingFormValue, daysOfWeek: parsedDaysOfWeek }
        )
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe({
          next: () => {
            const routePath = this.affiliateDigitalLeadSettingId ? 
              '../../../affiliate-digital-lead-settings' : 
              '../../affiliate-digital-lead-settings';

            this.router.navigate(
              [routePath],
              { relativeTo: this.route }
            );
            this.toastrService.success(
              `Setting successfully ${this.affiliateDigitalLeadSettingId ? 'updated' : 'created'}`,
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
          }
        });
    }
  }

  public getErrorMessage(
    controlName: keyof typeof this.affiliateDigitalLeadSettingForm.controls,
    label: string
  ): string {
    let errorMessage = '';
    const control = this.affiliateDigitalLeadSettingForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  public handleBackButtonClick(): void {
    this.router.navigate(['/admin/affiliate-digital-lead-settings']);
  }

  private setupInitialData(): void {
    this.coreService.setIsLoading(true);

    this.affiliateDigitalLeadSettingId = this.route.snapshot.params['id'];

    forkJoin([this.getLandingPages(), this.getAffiliateDigitalLeadSettings()])
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        complete: () => {
          this.setupFormInitialState();
        }
      });
  }

  private getLandingPages(): Observable<LandingPage[]> {
    return this.landingPageService
      .getAllLandingPages()
      .pipe(
        tap((landingPages) => {
          this.landingPages = landingPages;
        })
      );
  }

  private getAffiliateDigitalLeadSettings(): Observable<AffiliateDigitalLeadSettingsResponse> {
    return this.affiliatesService
      .getAffiliateDigitalLeadSettings()
      .pipe(
        tap((affiliateDigitalLeadSettingsResponse) => {
          this.affiliateDigitalLeadSettings = affiliateDigitalLeadSettingsResponse.affiliateDigitalLeadSettings;
        })
      )
  }

  private setupFormInitialState(): void {
    this.landingPages.forEach((landingPage) => {
      const existingCampaignSetting = this.affiliateDigitalLeadSettings
        .find((setting) => setting.campaign === landingPage.campaign);

      if (!existingCampaignSetting) {
        this.campaignInputOptions.push({
          label: landingPage.campaign,
          value: landingPage.campaign
        });
      }
    });

    if (this.affiliateDigitalLeadSettingId) {
      const affiliateDigitalLeadSettingToUpdate = this.affiliateDigitalLeadSettings
        .find((setting) => setting._id === this.affiliateDigitalLeadSettingId)!;

      this.affiliateDigitalLeadSettingForm.patchValue({
        campaign: affiliateDigitalLeadSettingToUpdate.campaign,
        daysOfWeek: affiliateDigitalLeadSettingToUpdate.daysOfWeek,
        fromHour: affiliateDigitalLeadSettingToUpdate.fromHour,
        toHour: affiliateDigitalLeadSettingToUpdate.toHour,
        timezone: affiliateDigitalLeadSettingToUpdate.timezone,
        amount: affiliateDigitalLeadSettingToUpdate.amount,
        states: affiliateDigitalLeadSettingToUpdate.states
      })
    }

    if (this.loggedUser?.attributes["custom:role"] !== this.roles.SuperAdmin) {
      this.affiliateDigitalLeadSettingForm.disable();
    }
  }
}
