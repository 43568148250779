import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

import { Roles } from "../../../core/types/role.type";
import { AuthService } from "../../../core/services/auth.service";

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  @Input() public appHasRole: Roles[];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    const loggedUser = this.authService.getLoggedUser();
    const loggedUserRole = loggedUser.attributes['custom:role'] as Roles;

    if (this.appHasRole.includes(loggedUserRole)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
