<app-card class="p-4 affiliates-list">
  <div class="d-flex align-items-center">
    <h2>Landing Pages</h2>
    <div *appHasRole="[roles.SuperAdmin]" class="flex-grow-1 d-flex justify-content-end">
      <button
        mat-raised-button
        color="primary"
        (click)="handleAddNewAffiliateButtonClick()"
      >
        Add Landing Page
      </button>
    </div>
  </div>
  <mat-form-field> 
    <input matInput placeholder="Search Landing Page" #input>
  </mat-form-field>
  <h3 class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">
    No data received
  </h3>
  <app-table
    *ngIf="tableRowData.length"
    [clickableRow]="true"
    [columnsHeadersMap]="tableColumnsAndHeaders"
    [rowData]="tableRowData"
    [paginatorConfig]="paginatorConfig"
    [disabledSortColumns]="disabledSortColumns"
    (onRowClick)="handleTableRowClick($event)"
    (onPageClick)="handlePageClick($event)"
    (onSortClick)="handleSortClick($event)"
    (onToggleClick)="handleToggleClick($event)"
  ></app-table>
</app-card>

<ng-container>
  <ng-template #activeAndDesactiveModalContentTemplate>
    <span>Are you sure you want to do this action?</span>
  </ng-template>
  
  <ng-template #activeAndDesactiveModalActionsTemplate let-actionData>
    <div class="
      d-flex
      align-items-center
      gap-3
      justify-content-end
    ">
      <button
        mat-button
        color="primary"
        (click)="handleModalToggleActionCancelButtonClick(actionData)"
      >
        Cancel
      </button>
      <button
        mat-button
        color="primary"
        (click)="handleModalToggleActionConfirmationButtonClick(actionData)"
      >
        Confirm
      </button>
    </div>
  </ng-template>
  
</ng-container>