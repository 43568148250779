import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { finalize } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { CoreService } from "../../../../core/services/core.service";
import { UsersService } from "../../../../core/services/users.service";

@Component({
  selector: 'app-activate-deactivate-user-modal',
  templateUrl: './activate-deactivate-user-modal.component.html'
})
export class ActivateDeactivateUserModalComponent {
  @ViewChild('activeAndDesactiveModalContentTemplate')
  public activeAndDesactiveModalContentTemplate: TemplateRef<any>;
  @ViewChild('activeAndDesactiveModalActionsTemplate')
  public activeAndDesactiveModalActionsTemplate: TemplateRef<any>;
  @Output() public cancel: EventEmitter<{ userId: string, action: boolean }> = new EventEmitter();
  @Output() public confirm: EventEmitter<void> = new EventEmitter();

  constructor(
    private coreService: CoreService,
    private usersService: UsersService,
    private toastrService: ToastrService
  ) {}

  public handleCancelButtonClick(
    { userId, action }: { userId: string, action: boolean }
  ): void {
    this.cancel.emit({ userId, action });
  }

  public handleModalToggleActionConfirmationButtonClick(
    { userId, action }: { userId: string, action: boolean }
  ): void {
    this.coreService.setIsLoading(true);
    this.usersService
      .adminUpdateUser(userId, { active: action })
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe({
        next: () => {
          this.confirm.emit();
          this.toastrService.success(
            `User has been successfully ${action ? 'activated' : 'deactivated'}`, 
            undefined, 
            { positionClass: 'toast-custom-bottom-center' }
          );
        },
        error: (error) => {
          this.cancel.emit({ userId, action });
          this.toastrService.error(
            error,
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
        }
      });
  }
}
