import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { 
  CreateDigitalLeadsUploadedDocument,
  DigitalLead, 
  DigitalLeadsListPageParams, 
  DigitalLeadsResponse, 
  EditDigitalLeadsSupplierDto, 
  SendDigitalLeadsToCampaignDto
} from "../types/digital-leads.types";

@Injectable({ providedIn: 'root' })
export class DigitalLeadsService {
  private apiUrl = `${environment.apiUrl}/digital-leads`;

  constructor(private http: HttpClient) {}

  public getAllDigitalLeads(
    { page, pageSize, orderByColumn, orderBy, search, active }: DigitalLeadsListPageParams
  ): Observable<DigitalLeadsResponse> {
    let params = new HttpParams()
    .set('page', page || 0)
    .set('pageSize', pageSize || 10)
    .set('orderBy', orderBy || 'DESC')
    .set('orderByColumn', orderByColumn || '_id')

    if (search) {
      params = params.set('search', search);
    }
    
    if (typeof active === 'boolean') {
      params = params.set('active', active);
    }

    return this.http.get<DigitalLeadsResponse>(`${this.apiUrl}`, { params });
  }

  public getDigitalLead(digitalLeadsId: string): Observable<DigitalLead> {
    return this.http.get<DigitalLead>(`${this.apiUrl}/${digitalLeadsId}`, { withCredentials: true });
  }

  public editDigitalLeads(digitalLeadsId: string, data: EditDigitalLeadsSupplierDto): Observable<DigitalLead> {
    return this.http.put<DigitalLead>(`${this.apiUrl}/${digitalLeadsId}`, data, { withCredentials: true });
  }

  public batchDigitalLeadsFile(
    supplierId: string,
    createUploadedDocuments: CreateDigitalLeadsUploadedDocument[],
  ): Observable<void> {
    const formData = new FormData();

    createUploadedDocuments.forEach((createUploadedDocument) => {
      createUploadedDocument.files.forEach((file) => {
        formData.append(createUploadedDocument.documentType, file);
      });
    });

    return this.http.post<void>(
      `${this.apiUrl}/${supplierId}/suppliers/batch`,
      formData
    );
  }

  public sendDigitalLeadsToCampaign(campaign: string, data: SendDigitalLeadsToCampaignDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${campaign}/campaigns`, data, { withCredentials: true });
  }
}
