import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AdminComponent } from "./admin.component";
import { SubmissionDetailsComponent } from "../../shared/components/submission-details/submission-details.component";
import { SubmissionsComponent } from "./submissions/submissions.component";
import { AffiliatesCreationEditionComponent } from "./affiliates/affiliates-creation-edition/affiliates-creation-edition.component";
import { AffiliatesComponent } from "./affiliates/affiliates.component";
import { LandingPagesComponent } from "./landing-pages/landing-pages.component";
import { LandingPageCreationEditionComponent } from "./landing-pages/landing-page-creation-edition/landing-page-creation-edition.component";
import { DirectmailImportsComponent } from "./directmail-imports/directmail-imports.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ReportsComponent } from "./reports/reports.component";
import { UsersComponent } from "./users/users.component";
import { UsersCreationEditionComponent } from "./users/users-creation-edition/users-creation-edition.component";
import { DigitalLeadsSuppliersComponent } from "./digital-leads-suppliers/digital-leads-suppliers.component";
import { DigitalLeadsSuppliersCreationEditionComponent } from "./digital-leads-suppliers/digital-leads-suppliers-creation-edition/digital-leads-suppliers-creation-edition.component";
import { DigitalLeadsComponent } from "./digital-leads/digital-leads.component";
import { DigitalLeadDetailsComponent } from "./digital-leads/digital-lead-details/digital-lead-details.component";
import { AffiliateDigitalLeadSettingsComponent } from "./affiliate-digital-lead-settings/affiliate-digital-lead-settings.component";
import { AffiliateDigitalLeadSettingsCreationEditionComponent } from "./affiliate-digital-lead-settings/affiliate-digital-lead-settings-creation-edition/affiliate-digital-lead-settings-creation-edition.component";
import { JobsComponent } from "./jobs/jobs.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          label: 'Dashboard'
        }
      },
      {
        path: 'leads',
        children: [
          {
            path: ':id',
            children: [
              {
                path: '',
                component: SubmissionsComponent
              },
              {
                path: 'details/:id',
                component: SubmissionDetailsComponent,
                data: {
                  breadcrumb: 'Lead Details',
                  label: 'Lead Details'
                }
              }
            ],
            data: {
              breadcrumb: 'Leads',
              label: 'Leads'
            }
          }
        ],
        data: {
          label: 'Leads'
        }
      },
      {
        path: 'affiliates',
        children: [
          {
            path: '',
            component: AffiliatesComponent,
          },
          {
            path: 'creation/:id',
            component: AffiliatesCreationEditionComponent,
            data: {
              breadcrumb: 'Edit Affiliate'
            }
          },
          {
            path: 'creation',
            component: AffiliatesCreationEditionComponent,
            data: {
              breadcrumb: 'Create Affiliate'
            }
          }
        ],
        data: {
          breadcrumb: 'Affiliates',
          label: 'Affiliates'
        }
      },
      {
        path: 'digital-leads-suppliers',
        children: [
          {
            path: '',
            component: DigitalLeadsSuppliersComponent,
          },
          {
            path: 'creation/:id',
            component: DigitalLeadsSuppliersCreationEditionComponent,
            data: {
              breadcrumb: 'Edit Digital Leads Supplier'
            }
          },
          {
            path: 'creation',
            component: DigitalLeadsSuppliersCreationEditionComponent,
            data: {
              breadcrumb: 'Create Digital Leads Supplier'
            }
          }
        ],
        data: {
          breadcrumb: 'Digital Leads Suppliers',
          label: 'Digital Leads Suppliers'
        }
      },
      {
        path: 'digital-leads',
        children: [
          {
            path: '',
            component: DigitalLeadsComponent,
          },
          {
            path: ':id',
            component: DigitalLeadDetailsComponent,
            data: {
              breadcrumb: 'Digital Lead Details'
            }
          },
        ],
        data: {
          breadcrumb: 'Digital Leads',
          label: 'Digital Leads'
        }
      },
      {
        path: 'affiliate-digital-lead-settings',
        children: [
          {
            path: '',
            component: AffiliateDigitalLeadSettingsComponent
          },
          {
            path: 'creation/:id',
            component: AffiliateDigitalLeadSettingsCreationEditionComponent,
            data: {
              breadcrumb: 'Edit Setting'
            }
          },
          {
            path: 'creation',
            component: AffiliateDigitalLeadSettingsCreationEditionComponent,
            data: {
              breadcrumb: 'Create Setting'
            }
          }
        ],
        data: {
          breadcrumb: 'Digital Lead Settings',
          label: 'Digital Lead Settings'
        }
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UsersComponent,
          },
          {
            path: 'creation/:id',
            component: UsersCreationEditionComponent,
            data: {
              breadcrumb: 'Edit User'
            }
          },
          {
            path: 'creation',
            component: UsersCreationEditionComponent,
            data: {
              breadcrumb: 'Create User'
            }
          }
        ],
        data: {
          breadcrumb: 'Users',
          label: 'Users'
        }
      },
      {
        path: 'landing-pages',
        children: [
          {
            path: '',
            component: LandingPagesComponent,
          },
          {
            path: 'creation/:id',
            component: LandingPageCreationEditionComponent,
            data: {
              breadcrumb: 'Edit Landing Page'
            }
          },
          {
            path: 'creation',
            component: LandingPageCreationEditionComponent,
            data: {
              breadcrumb: 'Create Landing Page'
            }
          }
        ],
        data: {
          breadcrumb: 'Landing Pages',
          label: 'Landing Pages'
        }
      },
      {
        path: 'directmail-imports',
        children: [
          {
            path: '',
            component: DirectmailImportsComponent,
          },
        ],
        data: {
          breadcrumb: 'Directmail Imports',
          label: 'Directmail Imports'
        }
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportsComponent,
          }
        ],
        data: {
          breadcrumb: 'Reports',
          label: 'Reports'
        }
      },
      {
        path: 'jobs',
        component: JobsComponent,
        data: {
          breadcrumb: 'Jobs',
          label: 'Jobs'
        }
      }
    ]
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class AdminRoutingModule {}
