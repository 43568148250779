import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AdminComponent } from "./admin.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "../../shared/shared.module";
import { AffiliatesComponent } from "./affiliates/affiliates.component";
import { AffiliatesCreationEditionComponent } from "./affiliates/affiliates-creation-edition/affiliates-creation-edition.component";
import { SubmissionsComponent } from "./submissions/submissions.component";
import { LandingPagesComponent } from "./landing-pages/landing-pages.component";
import { LandingPageCreationEditionComponent } from "./landing-pages/landing-page-creation-edition/landing-page-creation-edition.component";
import { DirectmailImportsComponent } from "./directmail-imports/directmail-imports.component";
import { UploadPurlsComponent } from "./directmail-imports/upload-purls/upload-purls.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ReportsComponent } from "./reports/reports.component";
import { UsersComponent } from "./users/users.component";
import { UsersCreationEditionComponent } from "./users/users-creation-edition/users-creation-edition.component";
import { ActivateDeactivateUserModalComponent } from "./users/activate-deactivate-user-modal/activate-deactivate-user-modal.component";
import { DeleteUserModalComponent } from "./users/delete-user-modal/delete-user-modal.component";
import { ImportUsersModalComponent } from "./users/import-users-modal/import-users-modal.component";
import { DigitalLeadsSuppliersComponent } from "./digital-leads-suppliers/digital-leads-suppliers.component";
import { DigitalLeadsSuppliersCreationEditionComponent } from "./digital-leads-suppliers/digital-leads-suppliers-creation-edition/digital-leads-suppliers-creation-edition.component";
import { DigitalLeadsComponent } from "./digital-leads/digital-leads.component";
import { DigitalLeadsListComponent } from "./digital-leads/digital-leads-list/digital-leads-list.component";
import { DigitalLeadDetailsModule } from "./digital-leads/digital-lead-details/digital-lead-details.module";
import { AffiliateDigitalLeadSettingsComponent } from "./affiliate-digital-lead-settings/affiliate-digital-lead-settings.component";
import { AffiliateDigitalLeadSettingsCreationEditionComponent } from "./affiliate-digital-lead-settings/affiliate-digital-lead-settings-creation-edition/affiliate-digital-lead-settings-creation-edition.component";
import { ActivateDeactivateAffiliateDigitalLeadSettingModalComponent } from "./affiliate-digital-lead-settings/activate-deactivate-affiliate-digital-lead-setting-modal/activate-deactivate-affiliate-digital-lead-setting-modal.component";
import { JobsComponent } from "./jobs/jobs.component";
import { ToggleJobStateModalComponent } from "./jobs/toggle-job-state-modal/toggle-job-state-modal.component";
import { ImportLeadsComponent } from "./digital-leads/digital-leads-list/import-leads/import-leads.component";
import { SendLeadsToCampaignComponent } from "./digital-leads/digital-leads-list/send-leads-to-campaign/send-leads-to-campaign.component";
import { DeleteDirectmailsImportModalComponent } from "./directmail-imports/delete-directmails-import-modal/delete-directmails-import-modal.component";

@NgModule({
  declarations: [
    AdminComponent,
    SubmissionsComponent,
    DashboardComponent,
    SidebarComponent,
    AffiliatesComponent,
    DigitalLeadsComponent,
    DigitalLeadsListComponent,
    DigitalLeadsSuppliersComponent,
    ImportLeadsComponent,
    SendLeadsToCampaignComponent,
    LandingPagesComponent,
    DirectmailImportsComponent,
    ReportsComponent,
    LandingPageCreationEditionComponent,
    AffiliatesCreationEditionComponent,
    DigitalLeadsSuppliersCreationEditionComponent,
    UploadPurlsComponent,
    DeleteDirectmailsImportModalComponent,
    UsersComponent,
    ImportUsersModalComponent,
    ActivateDeactivateUserModalComponent,
    DeleteUserModalComponent,
    UsersCreationEditionComponent,
    AffiliateDigitalLeadSettingsComponent,
    AffiliateDigitalLeadSettingsCreationEditionComponent,
    ActivateDeactivateAffiliateDigitalLeadSettingModalComponent,
    JobsComponent,
    ToggleJobStateModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AdminRoutingModule,
    SharedModule,
    DigitalLeadDetailsModule
  ],
  exports: [
    AdminComponent,
    DashboardComponent,
    UploadPurlsComponent,
    SubmissionsComponent,
    DirectmailImportsComponent,
    SidebarComponent,
    LandingPagesComponent,
    LandingPageCreationEditionComponent,
    AffiliatesComponent,
    AffiliatesCreationEditionComponent,
    DigitalLeadsSuppliersComponent,
    DigitalLeadsSuppliersCreationEditionComponent
  ],
  providers: [provideNgxMask()]
})
export class AdminModule {}
