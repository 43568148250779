import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { CreateUploadedDocument, DeleteUploadedDocument, DirectmailImport, DirectmailImportsListPageParams, DirectmailImportsResponse } from "../types/directmail.types";

@Injectable({ providedIn: 'root' })
export class DirectmailService {
  private apiUrl = `${environment.apiUrl}/directmails`;

  constructor(private http: HttpClient) {}

  public uploadPurlFile(
    campaign: string,
    createUploadedDocuments: CreateUploadedDocument[],
  ): Observable<DirectmailImport> {
    const formData = new FormData();

    createUploadedDocuments.forEach((createUploadedDocument) => {
      createUploadedDocument.files.forEach((file) => {
        formData.append(createUploadedDocument.documentType, file);
      });
    });

    return this.http.post<DirectmailImport>(
      `${this.apiUrl}/batch/${campaign}/campaign`,
      formData
    );
  }

  public getAllDirectmailImports({ page, pageSize, orderByColumn, orderBy, search }: DirectmailImportsListPageParams): Observable<DirectmailImportsResponse> {
    let params = new HttpParams()
    .set('page', page || 0)
    .set('pageSize', pageSize || 10)
    .set('orderBy', orderBy || 'DESC')
    .set('orderByColumn', orderByColumn || '_id');

    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<DirectmailImportsResponse>(`${this.apiUrl}/imports`, { params });
  }

  public deleteDirectmailFromImportFile(deleteUploadedDocument: DeleteUploadedDocument): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/imports/delete`, 
      { body: deleteUploadedDocument,  withCredentials: true }
    );
  }
}
