<app-card class="p-4">
  <form
    [formGroup]="affiliateDigitalLeadSettingForm"
    (submit)="handleAffiliateDigitalLeadSettingFormSubmit()"
  >
    <div class="row">
      <div class="col">
        <mat-form-field
          *ngIf="!affiliateDigitalLeadSettingId"
          hideRequiredMarker
          appearance="outline"
          class="w-100"
        >
          <mat-label>Campaign</mat-label>
          <mat-select formControlName="campaign">
            <mat-option *ngFor="let option of campaignInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('campaign', 'Campaign') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field
          hideRequiredMarker
          appearance="outline"
          class="w-100"
        >
          <mat-label>Days</mat-label>
          <mat-select formControlName="daysOfWeek" multiple>
            <mat-option *ngFor="let option of daysOfWeekInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('daysOfWeek', 'Days') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-time-input
          formControlName="fromHour"
          label="From"
          [minValue]="0"
          [maxValue]="22"
        ></app-time-input>
      </div>
      <div class="col-6">
        <app-time-input
          formControlName="toHour"
          label="To"
          [minValue]="1"
          [maxValue]="23"
        ></app-time-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field
          hideRequiredMarker
          appearance="outline"
          class="w-100"
        >
          <mat-label>Timezone</mat-label>
          <mat-select formControlName="timezone">
            <mat-option *ngFor="let option of timezoneInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('timezone', 'Timezone') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field
          hideRequiredMarker
          appearance="outline"
          class="w-100"
        >
          <mat-label>Amount</mat-label>
          <input
            matInput
            formControlName="amount"
            mask="separator.0"
            thousandSeparator=","
          />
          <mat-error>
            {{ getErrorMessage('amount', 'Amount') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field
          hideRequiredMarker
          appearance="outline"
          class="w-100"
        >
          <mat-label>States</mat-label>
          <mat-select formControlName="states" multiple>
            <mat-option *ngFor="let option of statesInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('states', 'States') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
        *appHasRole="[roles.SuperAdmin]"
        class="
          mt-4
          d-flex align-items-center
          justify-content-end
          gap-4
        ">
        <button
          mat-stroked-button
          type="button"
          color="primary"
          (click)="handleBackButtonClick()"
        >
          Back
        </button>
        <button 
          mat-raised-button
          type="submit"
          [disabled]="affiliateDigitalLeadSettingForm.invalid"
          color="primary"
        >
          Save
        </button>
      </div>
      </div>
    </div>
  </form>
</app-card>