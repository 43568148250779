import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, tap } from "rxjs";
import { format } from "date-fns";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { RowData, SortConfigOptions } from "../../../shared/components/table/table.component";
import { CoreService } from "../../../core/services/core.service";
import { PaginatorConfigOptions } from "../../../shared/components/table/table.component";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { DirectmailService } from '../../../core/services/directmail.service';
import { DirectmailImport, DirectmailImportsListPageParams } from '../../../core/types/directmail.types';
import { DeleteDirectmailsImportModalComponent } from './delete-directmails-import-modal/delete-directmails-import-modal.component';
import { Roles } from '../../../core/types/role.type';
import { AuthService } from '../../../core/services/auth.service';
import { AuthenticatedUser } from '../../../core/types/auth.types';

interface TableRowData {
  _id: string;
  campaign: string;
  file: string;
  records: number;
  createdAt: string;
}

export interface ImportEvent {
  directmailImport: DirectmailImport
}

@Component({
  selector: 'app-directmail-imports',
  templateUrl: './directmail-imports.component.html',
  styleUrls: ['./directmail-imports.component.scss']
})
export class DirectmailImportsComponent implements OnInit {
  @ViewChild('fileUploadModalActionsTemplate')
  private fileUploadModalActionsTemplate: TemplateRef<any>;
  @ViewChild(DeleteDirectmailsImportModalComponent) 
  private deleteDirectmailsImportModalComponent: DeleteDirectmailsImportModalComponent;
  public isLoading: Observable<boolean>;
  public loggedUser: AuthenticatedUser;
  public modalRef?: MatDialogRef<ModalComponent>;
  public paginatorConfig: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: 'DESC',
    orderByColumn: '_id'
  };
  public imports: DirectmailImport[];
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['campaign', 'Campaign'],
    ['user', 'Created By'],
    ['file', 'File'],
    ['records', 'Records'],
    ['createdAt', 'Date Created']
  ]);
  public tableRowData: TableRowData[] = [];

  @ViewChild('input') input: ElementRef;

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private dialog: MatDialog,
    private directmailService: DirectmailService,
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();

    setTimeout(() => {
      this.getAllDirectmailImports();
    });
  }

  public ngAfterViewInit(): void {
    if (this?.input?.nativeElement) {
      fromEvent(this.input.nativeElement,'keyup')
        .pipe(
            debounceTime(250),
            distinctUntilChanged(),
            tap(async () => {
              this.getDirectmailImportsData({
                page: 0, 
                pageSize: this.paginatorConfig.pageSize!,
                orderByColumn: this.sortConfig.orderByColumn,
                orderBy: this.sortConfig.orderBy,
                search: this.input.nativeElement.value
              });
            })
        )
        .subscribe();
    }
  }

  public handlePageClick(event: PageEvent): void {
    this.getDirectmailImportsData({
      page: event.pageIndex, 
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getDirectmailImportsData({
      page: 0,
      pageSize: this.paginatorConfig.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction
    });
  }

  public handleImportAction(event: ImportEvent): void {
    const directmailImport = event.directmailImport; 
    this.imports.unshift(directmailImport);
    this.paginatorConfig.length = this.paginatorConfig.length! + 1;
    
    this.prepareTableData();
  }

  private getAllDirectmailImports(): void {
    this.coreService.setIsLoading(true);
    this.directmailService.getAllDirectmailImports({
      page: this.paginatorConfig.pageIndex!, 
      pageSize: this.paginatorConfig.pageSize!
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllDirectmailImportsResponse) => {
      const { imports, ...pageData } = getAllDirectmailImportsResponse;
      this.imports = imports;
      this.paginatorConfig.length = pageData.totalItems;
      this.prepareTableData();
    })
  }

  public getDirectmailImportsData(params: DirectmailImportsListPageParams): void {
    this.coreService.setIsLoading(true);
    this.directmailService.getAllDirectmailImports({
      search: params.search,
      page: params.page || 0,
      pageSize: params.pageSize || 10,
      orderByColumn: params.orderByColumn || '_id',
      orderBy: params.orderBy || 'DESC',
    }).pipe(
      finalize(() => {
        this.coreService.setIsLoading(false);
      })
    ).subscribe((getAllDirectmailImportsResponse) => {
      const { imports, ...pageData } = getAllDirectmailImportsResponse;
      this.imports = imports;
      this.paginatorConfig.length = pageData.totalItems;
      this.paginatorConfig.pageIndex = pageData.page;
      this.paginatorConfig.pageSize = pageData.itemsPerPage;
      this.sortConfig.orderBy = pageData.orderBy;
      this.sortConfig.orderByColumn = pageData.orderByColumn;
      this.prepareTableData();
    })
  }

  private prepareTableData(): void {
    this.tableRowData = this.imports.map((directmailImport) => {
      const createdAt = this.formateDate(directmailImport.createdAt!);
      const allowedRoles = [Roles.Admin, Roles.SuperAdmin];

      return {
        _id: directmailImport._id,
        campaign: directmailImport.campaign,
        user: directmailImport.user || 'N/A',
        file: directmailImport.file,
        records: directmailImport.records,
        createdAt,
        actions: {
          delete: allowedRoles.includes(this.loggedUser.attributes["custom:role"] as Roles)
        }
      };
    });
  }

  private formateDate(date: Date): string {
    const newDate = new Date(date);
    return format(new Date(newDate), 'MM/dd/yyyy')
  }

  public handleButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        actionsTemplate: this.fileUploadModalActionsTemplate,
      },
      restoreFocus: false
    });
  }

  public handleDeleteButtonClick(row: RowData): void {
    const importsTableRowData = row as TableRowData;

    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Delete Directmails from Import file',
        contentTemplate: this.deleteDirectmailsImportModalComponent.deleteDirectmailsImportModalContentTemplate,
        actionsTemplate: this.deleteDirectmailsImportModalComponent.deleteDirectmailsImportModalActionsTemplate,
        context: { 
          fileName: importsTableRowData.file,
          campaignName: importsTableRowData.campaign,
          importFileId: importsTableRowData._id
        }
      },
      restoreFocus: false
    });
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  public handleDeleteDirectmailModalConfirmButtonClick(importId: string): void {
    this.imports = this.imports.filter((importFile) => importFile._id !== importId);

    this.prepareTableData();
    this.closeModal();
  }
}
