import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { TimeInputComponent } from "./time-input.component";

@NgModule({
  declarations: [TimeInputComponent],
  exports: [TimeInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class TimeInputModule {}
