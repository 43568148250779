import { Affiliate } from "./affiliate.types";
import { DigitalLeadsSupplier } from "./digital-leads.types";

export interface Report {
  _id: number;
  url: string;
  type?: ReportTypes;
  period: string;
  user: string;
  affiliate: Affiliate;
  digitalLeadsSupplier: DigitalLeadsSupplier; 
  createdAt: Date;
  updatedAt: Date;
}

export interface Reports {
  reports: Report[]
  page: number
  totalItems: number;
  itemsPerPage: number;
  orderBy: string
  orderByColumn: string;
}

export interface CreateReportDto {
  affiliateId?: number;
  type: ReportTypes;
  from: string;
  to: string;
}

export interface ReportListPageParams {
  page?: number;
  pageSize?:number; 
  orderByColumn?: string;
  orderBy?: string;
  search?: string;
}

export enum ReportOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ReportSortColumns {
  Id = 'id',
  User = 'user',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export enum ReportTypes {
  DigitalLeads = 'Digital Leads'
}
