import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, finalize, lastValueFrom, tap } from 'rxjs';

import { CoreService } from '../../../../core/services/core.service';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { CreateLandingPagePayload, LandingPage } from '../../../../core/types/landing-page.types';
import { LandingPageService } from '../../../../core/services/landing-page.service';
import { AffiliateService } from '../../../../core/services/affiliate.service';
import { Affiliate } from '../../../../core/types/affiliate.types';
import { AuthenticatedUser } from '../../../../core/types/auth.types';
import { Roles } from '../../../../core/types/role.type';
import { AuthService } from '../../../../core/services/auth.service';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-landing-page-creation-edition',
  templateUrl: './landing-page-creation-edition.component.html'
})
export class LandingPageCreationEditionComponent implements OnInit {
  public landingPage: LandingPage;
  public landingPageId: string
  public affiliates: Affiliate[];
  public selected: string | undefined
  public landingPageForm;
  public modalRef?: MatDialogRef<ModalComponent>;
  public affiliateInputOptions: SelectOption[] = [];
  public loggedUser: AuthenticatedUser;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private landingPageService: LandingPageService,
    private affiliateService: AffiliateService,
    private coreService: CoreService,
    private authService: AuthService,
    private toastrService: ToastrService
  ) { 
    this.landingPageForm = this.fb.nonNullable.group({
      domains: ['', [Validators.required, Validators.pattern(/^(?:[a-zA-Z0-9.-]+)(?:,[a-zA-Z0-9.-]+)*$/)]],
      affiliate: ['', Validators.required],
      emails: ['', [Validators.pattern(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}(\s*,\s*[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})*$/)]],
      campaign: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();
    this.landingPageId = this.route.snapshot.params['id'];

    setTimeout(() => {
      this.coreService.setIsLoading(true);
      this.setupAffiliates()
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe({
          complete: () => {
            if (this.landingPageId) {
              this.getLandingPage();
            }
          },
          error: (error) => {
            this.toastrService.error(
              error,
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
          }
        });
    });
  }

  public getErrorMessage(controlName: keyof typeof this.landingPageForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.landingPageForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  public handleBackButtonClick(): void {
    const routePath = this.landingPageId ? '../../../landing-pages' : '../../landing-pages';
    this.router.navigate(
      [routePath],
      { relativeTo: this.route }
    );
  }

  public async handleContinueButtonClick(): Promise<void> {
    this.landingPageForm.markAllAsTouched();

    if (this.landingPageForm.valid) {
      try {
        this.coreService.setIsLoading(true);

        const landingPageFormPayload = this.landingPageForm.getRawValue();
        const landingPagePayload: CreateLandingPagePayload = {
          affiliate: landingPageFormPayload.affiliate!,
          campaign: landingPageFormPayload.campaign!,
          domains: landingPageFormPayload.domains!.split(','),
          emailList: landingPageFormPayload.emails!.split(',')
        }
  
        if (!!this.landingPageId) {
          await lastValueFrom(this.landingPageService.editLandingPage(this.landingPageId, landingPagePayload))
        } else {
          await lastValueFrom(this.landingPageService.createLandingPage(landingPagePayload))
        }
  
        const routePath = this.landingPageId ? '../../../landing-pages' : '../../landing-pages';
  
        this.router.navigate(
          [routePath],
          { relativeTo: this.route }
        );
        const successMessage = `Landing Page was ${this.landingPageId ? 'updated' : 'created'} successfully`
        this.toastrService.success(successMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
  
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  private setupAffiliates(): Observable<Affiliate[]> {
    return this.affiliateService
      .getAllAffiliates()
      .pipe(
        tap((affiliates) => {
          this.affiliates = affiliates;
          this.affiliateInputOptions = affiliates.map((affiliate) => ({
            label: affiliate.name,
            value: affiliate._id
          }));
        })
      )
  }

  private getLandingPage(): void {
    this.landingPageService
      .getLandingPage(this.landingPageId)
      .subscribe((landingPage) => {
        const selectedAffiliate = this.affiliates.find(
          affiliate => affiliate._id === landingPage.affiliate as unknown as string
        )?._id;

        this.landingPage = landingPage;

        this.landingPageForm.patchValue({
          campaign: this.landingPage.campaign,
          domains: this.landingPage.domains.join(','),
          emails: this.landingPage.emailList.join(','),
          affiliate: selectedAffiliate
        });

        if (this.loggedUser.attributes['custom:role'] !== Roles.SuperAdmin) {
          this.landingPageForm.controls.domains.disable();
          this.landingPageForm.controls.campaign.disable();
        }
      });
  }
}
