<div>
  <img src="../../../assets/logos/kuber-logo.svg" alt="logo" />
</div>
<div>
  <nav class="
    mt-6
    d-flex
    flex-column
  ">
    <ng-container *ngFor="let navItem of navItems">
      <div *ngIf="shouldRenderNavItem(navItem)" class="nav-item">
        <a
          class="d-flex flex-column"
          routerLinkActive="active-route"
          [routerLink]="navItem.url"
        >
          <div class="main-nav" (click)="navItem.expanded = !navItem.expanded">
            <div class="d-flex align-items-center gap-3">
              <mat-icon>{{ navItem.icon }}</mat-icon>
              <span [innerHTML]="navItem.label"></span>
            </div>
            <button
              *ngIf="navItem?.subMenu"
              class="p-0"
              mat-icon-button
            >
              <mat-icon>
                {{navItem.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
              </mat-icon>
            </button>   
          </div>
          <div
            *ngIf="navItem.subMenu && navItem.expanded"
            class="d-flex flex-column gap-2"
            [class.slide-down]="navItem.expanded"
          >
            <ng-container *ngFor="let subNavItem of navItem.subMenu">
              <div *ngIf="shouldRenderNavItem(navItem)">
                <a
                  class="
                    sub-nav
                    d-flex
                    align-items-center
                    gap-1
                    ms-3
                  "
                  routerLinkActive="active-route"
                  [routerLink]="subNavItem.url"
                >
                  <mat-icon>{{ subNavItem.icon }}</mat-icon>
                  <span class="text-capitalize" [innerHTML]="subNavItem.label"></span>
                </a>
              </div>
            </ng-container>
          </div>
        </a>
      </div>
    </ng-container>
  </nav>
</div>